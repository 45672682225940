import http from '@/utils/request';

/**
 *  ---iot物联网平台---
 *  */

/** 获取 物模型属性列表  /object/model/property/getList */
export async function getPropertyList(params: any) {
  return http.get({ url: '/hohai/iot/object/model/property/getList', params });
}

/** 获取 物模型事件列表  /object/model/event/getList */
export async function getEventList(params: any) {
  return http.get({ url: '/hohai/iot/object/model/event/getList', params });
}

/** 获取 物模型服务列表  /object/model/service/getList */
export async function getServiceList(params: any) {
  return http.get({ url: '/hohai/iot/object/model/service/getList', params });
}

/** 获取 产品类型列表  product/type/getList */
export async function getProductTypeList(params: any) {
  return http.get({ url: '/hohai/iot/product/type/getList', params });
}

/** 获取 产品列表  product/getList */
export async function getProductList(params: any) {
  return http.get({ url: '/hohai/iot/product/getList', params });
}

/** 获取 modbus上下行  /upAndDownConfig/modbus/getList  */
export async function getModbusUpAndDownConfigList(params: any) {
  return http.get({ url: '/hohai/iot/upAndDownConfig/modbus/getList', params });
}

/** 获取 mqtt上下行  /upAndDownConfig/mqtt/getList */
export async function getMqttUpAndDownConfigList(params: any) {
  return http.get({ url: '/hohai/iot/upAndDownConfig/mqtt/getList', params });
}

/** 获取 http上下行  /upAndDownConfig/http/getList */
export async function getHttpUpAndDownConfigList(params: any) {
  return http.get({ url: '/hohai/iot/upAndDownConfig/http/getList', params });
}

/** 获取 网络组件列表  /network/components/getList */
export async function getNetworkList(params: any) {
  return http.get({ url: '/hohai/iot/network/components/getList', params });
}
