import http from '@/utils/request';

/**
 *  ---交付工程管理平台---
 *  */

/** 获取 项目列表  /project/list*/
export async function getProjectList(params: any) {
  return http.get({ url: '/hohai/project/project/list', params });
}

/** 项目类型列表 --/project/type/list */
export async function getProjectTypeList() {
  return http.get({ url: '/hohai/project/project/type/list' });
}

/** 项目成本类型列表 --/costType/list */
export async function getProjectCostTypeList(params: any) {
  return http.get({ url: '/hohai/project/costType/list', params });
}

/** 项目成本分项列表 --/costSubclass/list */
export async function getProjectCostSubclassTypeList(params: any) {
  return http.get({ url: '/hohai/project/costSubclass/list', params });
}

/** 项目成本单位列表 --/costUnit/list */
export async function getProjectCostUnitList(params: any) {
  return http.get({ url: '/hohai/project/costUnit/list', params });
}
