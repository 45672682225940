import http from '@/utils/request';

/**
 *  ---数据中台---
 *  */
/** 获取数据源列表 */
export async function getDataSourceList() {
  return http.post({ url: '/hohai/data/exchange/data/source/itemList' });
}
/** 获取数据表列表 */
export async function getDataTableList(params: any) {
  return http.get({ url: '/hohai/data/exchange/metadata/getTables', params });
}

/** 获取--新--数据表列表 */
export async function getNewDataTableList(params: any) {
  return http.get({ url: '/hohai/data/platform/metadata/getTableNames', params });
}

/** 获取数据字段列表 */
export async function getDataFieldList(params: any) {
  return http.get({ url: '/hohai/data/exchange/metadata/getColumns', params });
}

/** 获取--新--数据字段列表 */
export async function getNewDataFieldList(params: any) {
  return http.post({ url: '/hohai/data/platform/metadata/list', params });
}

/** 获取数据字段列表--sql */
export async function getDataFieldBySqlList(params: any) {
  return http.get({ url: '/hohai/data/exchange/metadata/getColumnsByQuerySql', params });
}

/** 获取数据标签数 */
export async function getDataTagTree() {
  return http.post({ url: '/hohai/data/platform/asset/label/getTree' });
}

/** 获取-字段类型列表 */
export async function getDataFieldTypeSelectList() {
  return http.get({ url: '/hohai/data/platform/asset/field/getTypeList' });
}

/** 获取所有应用 */
export async function getAllApplicationList(params: any) {
  return http.post({
    url: `/hohai/data/platform/app/info/getList`,
    params,
  });
}

/** 获取所有服务 */
export async function getAllServiceList(params: any) {
  return http.post({
    url: `/hohai/data/platform/api/info/getList`,
    params,
  });
}
