import http from '@/utils/request';

/** 获取管理员日历 */
export async function getCalendar(params: API.pageParams) {
  return http.get({ url: '/hohai/inspection/mission/getCalendar', params: { ...params } });
}
/** 获取个人日历 */
export async function getOwnerCalendar(params: API.pageParams) {
  return http.get({ url: '/hohai/inspection/mission/getOwnerCalendar', params: { ...params } });
}

/** 获取右侧任务列表 */
export async function getMissionList(params: API.pageParams) {
  return http.get({ url: '/hohai/inspection/mission/getMissionList', params: { ...params } });
}

/** 新建任务 */
export async function save(data: any) {
  return http.post({
    url: '/hohai/inspection/mission/save',
    data,
    options: { headers: { 'Content-Type': 'application/json' } },
  });
}
/** 批量新建任务 */
export async function batchSave(data: any) {
  return http.post({
    url: '/hohai/inspection/batch/mission/save',
    data,
    options: { headers: { 'Content-Type': 'application/json' } },
  });
}

/** 修改任务 */
export async function update(data: any) {
  return http.post({
    url: '/hohai/inspection/mission/update',
    data,
    options: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  });
}

/** 删除任务 */
export async function remove(params: any) {
  return http.post({ url: '/hohai/inspection/mission/delete', params });
}

/** 获取单条数据 */
export async function getInfo(params: any) {
  return http.get({ url: '/hohai/auth/menu/info', params });
}
