import { useEffect, useState, useRef, useContext } from 'react';
import { useModel, history } from 'umi';

import { Context } from '@/store/provider';
import Socket from '@/utils/socket';
import { getToken } from '@/utils/utils';
import NoticeIcon from './NoticeIcon';
import { readTodo, readBulletin, readAlarm } from '@/pages/account/MessagesCenter/service';
import View from '@/pages/account/MessagesCenter/components/ViewForm';

import styles from './index.less';

import type { noticeItem } from './data.d';

export type GlobalHeaderRightProps = {
  fetchingNotices?: boolean;
  onNoticeVisibleChange?: (visible: boolean) => void;
  onNoticeClear?: (tabName?: string) => void;
};

// 获取未读通知
const getUnreadData = (noticeData: noticeItem[]) => {
  if (Array.isArray(noticeData)) {
    return noticeData.filter((item) => !item.read).length;
  } else {
    return 0;
  }
};

const NoticeIconView: React.FC = () => {
  const {
    todoList,
    getTodoItemListData,
    bulletinList,
    getBulletinItemListData,
    alarmList,
    getAlarmItemListData,
  } = useModel('CommonM', (model) => ({
    todoList: model.todoList, // 待办
    getTodoItemListData: model.getTodoItemListData, // 待办
    bulletinList: model.bulletinList, // 公告通知
    getBulletinItemListData: model.getBulletinItemListData, // 公告通知
    alarmList: model.alarmList, // 报警
    getAlarmItemListData: model.getAlarmItemListData, // 报警
  }));

  const { state, dispatch } = useContext(Context);

  const [popupVisible, setPopupVisible] = useState<boolean>(false);
  // 弹窗
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  // 查看/编辑页面数据
  const [itemFormData, setItemFormData] = useState<any>({ id: '0' });
  const [formLoading, setFormLoading] = useState<boolean>(true);

  const ref = useRef<NodeJS.Timer>();
  const needLoadData = useRef<number>(0);

  // 加载消息数据
  const loadData = () => {
    getTodoItemListData({ dealt: false });
    getBulletinItemListData({ dealt: false });
    getAlarmItemListData({ dealt: false });
  };

  const onSocketMsg = (e: any) => {
    if (e.data !== 'connected' && e.data !== 'signed' && e.data !== 'connecting') {
      const msg = JSON.parse(e.data);
      // 接收消息 id
      Socket.websocketsend({
        socketType: '2',
        token: getToken(),
        messageIds: [msg.id],
      });
      // 新消息 待刷新
      if (msg.type === 'todo' || msg.type === 'bulletin' || msg.type === 'alarm') {
        needLoadData.current = needLoadData.current + 1;
      }
    }
  };

  const onError = (e: any) => {
    console.log('socke异常', e);
  };

  const onClose = (e: any) => {
    console.log('socke关闭', e);
  };

  useEffect(() => {
    Socket.created({
      onMessage: onSocketMsg,
      onClose: onClose,
      onError: onError,
    });

    // loadData();

    const timer = setInterval(() => {
      // 有待刷新 更新消息数据
      if (needLoadData.current > 0) {
        loadData();
        needLoadData.current = 0;
      }
    }, 100);
    ref.current = timer;
    return () => {
      //组件销毁的时候会被调用
      clearInterval(ref.current);
    };
  }, []);

  useEffect(() => {
    loadData();
  }, [state.unReadMessageNumber]);

  const changeReadState = (item: any, key: string) => {
    switch (key) {
      case 'bulletin':
        readBulletin({ noticesId: item.id });
        break;
      case 'todo':
        readTodo({ noticesId: item.id });
        break;
      case 'alarm':
        readAlarm({ noticesId: item.id });
        break;
    }
    dispatch({
      type: 'setUnReadMessageNumber',
      payload: { unReadMessageNumber: state.unReadMessageNumber - 1 },
    });
    setItemFormData(item);
    setFormLoading(false);
    setModalVisible(true);
    setPopupVisible(false);
  };

  // modal 通用
  const modalProps = {
    formLoading,
    setFormLoading,
    modalVisible,
    setModalVisible,
    itemFormData,
  };

  return (
    <>
      <NoticeIcon
        className={styles.action}
        count={
          getUnreadData(todoList) + getUnreadData(bulletinList) + getUnreadData(alarmList) || 0
        }
        onItemClick={(item, props) => {
          changeReadState(item, props.tabKey);
        }}
        loading={false}
        viewMoreText="查看更多"
        popupVisible={popupVisible}
        onPopupVisibleChange={() => setPopupVisible(!popupVisible)}
        onViewMore={() => {
          setPopupVisible(false);
          history.push({
            pathname: '/account/messages',
          });
        }}
        clearClose
      >
        <NoticeIcon.Tab
          tabKey="todo"
          count={getUnreadData(todoList)}
          list={todoList}
          title="待办"
          emptyText="暂无待办"
          showViewMore
          showClear={false}
        />
        <NoticeIcon.Tab
          tabKey="bulletin"
          count={getUnreadData(bulletinList)}
          list={bulletinList}
          title="系统消息"
          emptyText="暂无消息"
          showViewMore
          showClear={false}
        />

        <NoticeIcon.Tab
          tabKey="alarm"
          title="报警"
          emptyText="暂无报警"
          count={getUnreadData(alarmList)}
          list={alarmList}
          showViewMore
          showClear={false}
        />
      </NoticeIcon>
      {modalVisible && <View {...modalProps} />}
    </>
  );
};

export default NoticeIconView;
