import React, { useReducer } from 'react';
import type { globalStore, IContextProps } from './index.d';

export const store: globalStore = {
  //第1步.将数据集中在一个store对象
  unReadMessageNumber: 0, // 未读消息数
  coordinatesType: 'bd09', // 默认百度坐标系
  pageLoading: false, // 页面加载状态
};

export const reducer = (state: globalStore, action: any) => {
  //第2步.将所有操作集中在reducer
  switch (action.type) {
    case 'init':
      return { ...store };
    case 'setUnReadMessageNumber':
      return { ...state, unReadMessageNumber: action.payload.unReadMessageNumber };
    case 'setCoordinatesType':
      return { ...state, setCoordinatesType: action.payload.setCoordinatesType };
    case 'setPageLoading':
      return { ...state, setPageLoading: action.payload.setPageLoading };

    default:
      // throw new Error();
      return state;
  }
};

export const Context = React.createContext({} as IContextProps); //第3步.创建一个Context对象

const Provider = ({ children, routes }: { children: any; routes: any }) => {
  const [state, dispatch] = useReducer(reducer, store); //第4步.创建对数据的读写API

  return (
    <Context.Provider value={{ state, dispatch }}>
      {React.cloneElement(children!, { ...children!.props, routes })}
    </Context.Provider>
  );
};

export default Provider;
