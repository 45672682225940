// export default () => '这是巡查日历';

import { useCallback, useState } from 'react';
import moment from 'moment';
import { getCalendar, getMissionList } from '@/pages/Patrol/PatrolCalendar/service';
import type { calendarNoteItem, taskItem } from '../pages/Patrol/PatrolCalendar/data';

export default function usePatrolCalendarM() {
  const [calendarNoteList, setCalendarNoteList] = useState<calendarNoteItem[]>([]); // 日历备注
  const [taskList, setTaskList] = useState<taskItem[]>([]); // 日历右侧列表

  // 获取日历备注
  const getCalendarNoteList = useCallback(async (queryDate: moment.Moment) => {
    const res = await getCalendar({
      year: parseInt(moment(queryDate).format('YYYY')),
      month: parseInt(moment(queryDate).format('MM')),
    });
    if (res.code === '0') {
      setCalendarNoteList(res.data);
    }
  }, []);

  // 获取日历右侧列表
  const getTaskList = useCallback(async (day: moment.Moment) => {
    const res = await getMissionList({
      day: moment(day).format('YYYY-MM-DD'),
    });
    if (res.code === '0') {
      setTaskList(res.data);
    }
  }, []);

  return {
    calendarNoteList,
    getCalendarNoteList,

    taskList,
    getTaskList,
  };
}
