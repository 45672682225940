import http from '@/utils/request';
/**
 *  ---基础设施---
 *  */

/** 获取数据字典列表 */
export async function getDataDictionaryList(params: any) {
  return http.get({ url: `/hohai/infrastructure/dataDictionary/getList`, params });
}

/** 获取数据字典数据项列表 */
export async function getDataItemList(params: any) {
  return http.get({ url: `/hohai/infrastructure/dataDictionary/getDataItemList`, params });
}

/**
 *
 * @description  /infrastructure/facilityType/getList
 * @description 获取设施类型列表
 * @param eqCode  精确code
 * @param Code  模糊code
 *  */
export async function getFacilityTypeList(params: any) {
  return http.get({ url: `/hohai/infrastructure/facilityType/getList`, params });
}

/**
 *
 * @description  /infrastructure/facilityType/getByCode
 * @description 获取设施类型列表
 * @param Code code 必填
 *  */
export async function getFacilityTypeByCode(params: any) {
  return http.get({ url: `/hohai/infrastructure/facilityType/getByCode`, params });
}

/** 获取设施列表 */
export async function getFacilityList(data: any) {
  return http.post({ url: `/hohai/infrastructure/facility/getList`, data });
}
