import React from 'react';
import { Descriptions, Spin } from 'antd';
import { history } from 'umi';
import HModal from '@/components/HModel';

const Add: React.FC<{
  itemFormData: any;
  formLoading: boolean;
  modalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ itemFormData = {}, formLoading, modalVisible, setModalVisible }) => {
  const fieldObj = [
    {
      key: 'detail',
      title: '',
      data: [
        { label: '通知标题', value: 'title' },
        { label: '创建时间', value: 'createTime' },
        { label: '发布时间', value: 'sendTime' },
        { label: '通知内容', value: 'content' },
      ],
    },
  ];

  const onOk = () => {
    if (itemFormData.url && itemFormData.url !== '/')
      window.open(
        `http://${window.location.host}/#${itemFormData.url}${
          itemFormData.url.includes('?') ? '&' : '?'
        }auditRecordId=${itemFormData.businessId}`,
      );
    // if (itemFormData.url.split('?').length > 1) {
    //   // const params = new URLSearchParams(itemFormData.url.split('?')[1]);
    //   // history.push({
    //   //   pathname: itemFormData.url.split('?')[0],
    //   //   query: { ...Object.fromEntries(params.entries()), auditRecordId: itemFormData.businessId },
    //   // });
    // } else {
    //   // history.push({
    //   //   pathname: itemFormData.url,
    //   //   query: { auditRecordId: itemFormData.businessId },
    //   // });
    // }
    setModalVisible(false);
  };

  return (
    <HModal
      title="详情"
      open={modalVisible}
      onCancel={() => {
        setModalVisible(false);
      }}
      onOk={onOk}
      okText={itemFormData.dealt === true ? '已处理' : '去处理'}
      okButtonProps={
        itemFormData.url === '' ||
        itemFormData.url === '/' ||
        itemFormData.url === null ||
        itemFormData.dealt === true
          ? {
              style: { display: 'none' },
              // disabled: true,
            }
          : {}
      }
    >
      <Spin spinning={formLoading}>
        {fieldObj.map((item) => (
          <Descriptions key={item.key} title={item.title} column={1}>
            {item.data.map((e) => (
              <Descriptions.Item key={e.value} label={e.label}>
                {itemFormData[e.value!] || ''}
              </Descriptions.Item>
            ))}
          </Descriptions>
        ))}
      </Spin>
    </HModal>
  );
};
export default Add;
