// export default () => '这是巡查日历';

import { useCallback, useState } from 'react';

import {
  getMenuTree,
  getRoleList,
  getUserList,
  getDeviceList,
  getAcqDeviceList,
  getBindDeviceList,
  getPatrolPointList,
  getTaskTypeList,
  getCargoList,
  getCargoTypeList,
  getCargoUnitList,
  getInboundTypeList,
  getOutboundTypeList,
  getRepositoryList,
  getDataSourceList,
  getDataTableList,
  getDataFieldList,
  getDataFieldBySqlList,
  getDataTagTree,
  getDataFieldTypeSelectList,
  getAllApplicationList,
  getAllServiceList,
  getContractList,
  getProjectTypeList,
  getProjectList,
  getProjectCostType,
  getFileFolderList,
  getProjectByContractList,
  getTodoItemList,
  getAlarmItemList,
  getBulletinItemList,
  getProjectMemberList,
  getDeviceModelTypeList,
  getDevicePacketList,
  getDeviceFieldList,
  getDeviceTypeList,
  getOnlineDeviceList,
  getInstructionList,
  getDeviceCmdList,
  getDeviceClassList,
} from '../services/Common';

// // 高标准农田
// import {
//   getIrrigationList,
//   getPlantingCropList,
//   getPumpRoomList,
//   getPumpRoomDeviceList,
// } from '../services/Farmland';

import { changeTree, getTreeNode, addTreeRoot } from '@/utils/utils';

import type { DefaultOptionType } from 'antd/lib/select';
import type { DataNode } from 'antd/es/tree';

export default function useCommonM() {
  // 菜单状态
  const statusEnum: DefaultOptionType[] = [
    { id: '1', value: 'true', label: '正常' },
    { id: '2', value: 'false', label: '停用' },
  ];
  // 菜单布局
  const menuLayoutEnum: DefaultOptionType[] = [
    { id: '1', value: 'true', label: '菜单页面' },
    { id: '2', value: 'false', label: '全屏页面' },
  ];

  // 是否枚举
  const booleanStringEnum: DefaultOptionType[] = [
    { id: '1', value: 'true', label: '是' },
    { id: '2', value: 'false', label: '否' },
  ];
  // 是否枚举
  const booleanEnum: API.selectItem[] = [
    { id: '1', value: true, label: '是' },
    { id: '2', value: false, label: '否' },
  ];

  // 菜单类型
  const menuTypeEnum: DefaultOptionType[] = [
    { id: '1', value: 'M', label: '目录' },
    { id: '2', value: 'C', label: '菜单' },
    // {id:'3', value: 'F', label: '按钮' },
  ];

  // 性别
  const sexEnum: DefaultOptionType[] = [
    { id: '1', value: '男', label: '男' },
    { id: '2', value: '女', label: '女' },
    { id: '3', value: '未知', label: '未知' },
  ];

  // 设备类型
  const deviceTypeEnum: DefaultOptionType[] = [
    { id: '1', value: 'DEVICE_TYPE_FLOW', label: '流量计' },
    { id: '2', value: 'DEVICE_TYPE_RAIN', label: '雨量计' },
    { id: '3', value: 'DEVICE_TYPE_GATE', label: '闸位计' },
    { id: '4', value: 'DEVICE_TYPE_WATER', label: '水位计' },
  ];

  // 设备状态
  const deviceStatusEnum: DefaultOptionType[] = [
    { id: '1', value: 'DEVICE_STATUS_ONLINE', label: '在线' },
    { id: '2', value: 'DEVICE_STATUS_OFFLINE', label: '离线' },
  ];

  // 设备指令状态
  const deviceCmdEnum: DefaultOptionType[] = [
    { id: '1', value: 'CMD_TYPE_OPEN', label: '开启' },
    { id: '2', value: 'CMD_TYPE_CLOSE', label: '关闭' },
  ];

  // 设备维修状态状态
  const repairEnum: DefaultOptionType[] = [
    { id: '1', value: 'UNDER_REPAIR', label: '正在维修' },
    { id: '2', value: 'MAINTENANCE_COMPLETE', label: '维修结束' },
  ];

  // 设备地点属性
  const deviceLocalEnum: DefaultOptionType[] = [
    { id: '1', value: 'PUMP_STATION', label: '泵站' },
    { id: '2', value: 'FIELD', label: '田间' },
    { id: '3', value: 'CANAL_SYSTEM', label: '渠系' },
  ];

  // 任务状态
  const taskStatusEnum: DefaultOptionType[] = [
    { id: '1', value: 'MISSION_STATUS_NOT_STARTED', label: '未开始', color: '#fa8c16' },
    { id: '2', value: 'MISSION_STATUS_IN_PROGRESS', label: '进行中', color: '#1890ff' },
    { id: '3', value: 'MISSION_STATUS_INCOMPLETE', label: '未完成', color: '#f5222d' },
    { id: '4', value: 'MISSION_STATUS_COMPLETED', label: '已完成', color: '#3FC57F' },
  ];
  // 任务类型
  const taskTypeEnum: DefaultOptionType[] = [
    { id: '1', value: 'MISSION_TYPE_BURST', label: '突发' },
    { id: '2', value: 'MISSION_TYPE_ROUTINE', label: '常规' },
  ];
  // 周期
  const periodEnum: DefaultOptionType[] = [
    { id: '1', value: 'MISSION_PERIOD_EVERYDAY', label: '每天' },
    { id: '2', value: 'MISSION_PERIOD_WORK_DAY', label: '工作日' },
    { id: '3', value: 'MISSION_PERIOD_WEEK', label: '每周' },
    { id: '4', value: 'MISSION_PERIOD_CUSTOM', label: '自定义周期' },
  ];

  // 紧急程度
  const emergencyLevelEnum: DefaultOptionType[] = [
    { id: '1', value: 'EMERGENCY_LEVEL_NORMAL', label: '一般险情' },
    { id: '2', value: 'EMERGENCY_LEVEL_SERIOUS', label: '严重险情' },
  ];

  // GIS类型
  const gisTypeEnum: DefaultOptionType[] = [
    { id: '1', value: 'Point', label: '点' },
    { id: '2', value: 'LineString', label: '线' },
    { id: '3', value: 'Polygon', label: '面' },
  ];

  // 系统设置
  const [menuTree, setMenuTree] = useState<DataNode[]>([]); // 菜单树
  const [menuTreeNode, setMenuTreeNode] = useState<string[]>([]); // 菜单节点
  const [roleSelectList, setRoleSelectList] = useState<DefaultOptionType[]>([]); // 角色列表
  const [userSelectList, setUserSelectList] = useState<DefaultOptionType[]>([]); // 用户列表
  // 设备
  const [deviceSelectList, setDeviceSelectList] = useState<DefaultOptionType[]>([]); // 设备列表
  const [acqDeviceList, setAcqDeviceList] = useState<DefaultOptionType[]>([]); // 采集设备列表
  const [deviceTypeList, setDeviceTypeList] = useState<DefaultOptionType[]>([]); // 设备类型列表
  const [deviceClassList, setDeviceClassList] = useState<DefaultOptionType[]>([]); // 设备分类列表
  const [deviceModelTypeList, setDeviceModelTypeList] = useState<DefaultOptionType[]>([]); // 设备型号类型
  const [devicePacketList, setDevicePacketList] = useState<DefaultOptionType[]>([]); // 设备报文
  const [deviceFieldList, setDeviceFieldList] = useState<DefaultOptionType[]>([]); // 设备字段
  const [onlineDeviceList, setOnlineDeviceList] = useState<DefaultOptionType[]>([]); // 在线设备
  const [instructionList, setInstructionList] = useState<DefaultOptionType[]>([]); // 指令
  const [deviceCmdList, setDeviceCmdList] = useState<DefaultOptionType[]>([]); // 设备指令
  // 运维巡检
  const [patrolPointSelectList, setPatrolPointSelectList] = useState<DefaultOptionType[]>([]); // 巡查点列表
  const [taskTypeSelectList, setTaskTypeSelectList] = useState<DefaultOptionType[]>([]); // 巡查任务类型列表
  // 库存管理
  const [cargoSelectList, setCargoSelectList] = useState<DefaultOptionType[]>([]); // 货物列表
  const [cargoTypeSelectList, setCargoTypeSelectList] = useState<DefaultOptionType[]>([]); // 货物类型列表
  const [cargoUnitSelectList, setCargoUnitSelectList] = useState<DefaultOptionType[]>([]); // 货物类型列表
  const [inboundTypeSelectList, setInboundTypeSelectList] = useState<DefaultOptionType[]>([]); // 入库类型列表
  const [outboundTypeSelectList, setOutboundTypeSelectList] = useState<DefaultOptionType[]>([]); // 出库类型列表
  const [repositoryTree, setRepositoryTree] = useState<DataNode[]>([]); // 仓库列表
  // 数据中台
  const [dataSourceSelectList, setDataSourceSelectList] = useState<DefaultOptionType[]>([]); // 数据源列表
  const [dataTableSelectList, setDataTableSelectList] = useState<DefaultOptionType[]>([]); // 数据表格列表
  const [dataFieldList, setDataFieldList] = useState<API.CheckboxItem[]>([]); // 数据字段列表
  const [dataTagTree, setDataTagTree] = useState<DataNode[]>([]); // 数据标签树
  const [dataTagTreeNode, setDataTagTreeNode] = useState<string[]>([]); // 数据标签节点
  const [dataFieldTypeSelectList, setDataFieldTypeSelectList] = useState<DefaultOptionType[]>([]); // 数据中台-字段类型
  const [dataApplicationList, setDataApplicationList] = useState<DefaultOptionType[]>([]); // 数据中台-所有应用
  const [dataServiceList, setDataServiceList] = useState<DefaultOptionType[]>([]); // 数据中台-所有服务
  // 项目管理
  const [contractList, setContractList] = useState<DefaultOptionType[]>([]); // 项目管理-所有项目合同
  const [projectType, setProjectType] = useState<DefaultOptionType[]>([]); // 项目管理-项目类型列表
  const [projectTypeTree, setProjectTypeTree] = useState<DataNode[]>([]); // 项目管理-项目类型树
  const [project, setProject] = useState<DefaultOptionType[]>([]); // 项目管理-项目列表
  const [projectTree, setProjectTree] = useState<DataNode[]>([]); // 项目管理-项目树
  const [fileFolderTree, setFileFolderTree] = useState<DataNode[]>([]); // 文件路径树
  const [fileList, setFileList] = useState<Flow.fileItem[]>([]); // 文件路径树
  const [projectCostTypeList, setProjectCostTypeList] = useState<DefaultOptionType[]>([]); // 项目费用类型
  const [projectByContract, setProjectByContract] = useState<DataNode[]>([]); // 合同下项目列表
  const [projectMemberList, setProjectMemberList] = useState<API.noticeItem[]>([]); // 项目成员列表
  // 消息
  const [todoList, setTodoList] = useState<API.noticeItem[]>([]); // 待办--列表
  const [bulletinList, setBulletinList] = useState<API.noticeItem[]>([]); // 公告通知
  const [alarmList, setAlarmList] = useState<API.noticeItem[]>([]); // 报警

  // 获取菜单树
  const getMenuTreeData = useCallback(async (params: any) => {
    const res = await getMenuTree(params);
    if (res.data) {
      setMenuTree(
        addTreeRoot(changeTree(res.data, 'menuName', 'id', 'routes'), '一级菜单', '-1', true),
      );
      setMenuTreeNode(getTreeNode(res.data, 'id', 'routes'));
    }
  }, []);

  // 获取角色列表
  const getRoleSelectListData = useCallback(async () => {
    const res = await getRoleList();
    if (res.data) {
      setRoleSelectList(
        res.data.map((item: any) => ({
          id: item.id,
          label: item.roleName,
          value: item.id,
          code: item.roleCode,
        })),
      );
    }
  }, []);

  // 获取用户列表
  const getUserSelectListData = useCallback(async () => {
    const res = await getUserList();
    if (res.data) {
      setUserSelectList(
        res.data.map((item: API.UserItem) => ({
          id: item.id,
          label: `${item.realName}（${item.userName}）`,
          value: item.id,
        })),
      );
    }
  }, []);

  // 获取设备列表
  const getDeviceSelectListData = useCallback(async (params: any) => {
    const res = await getDeviceList(params);
    // const bindData = await getBindDeviceList();
    if (res.data) {
      setDeviceSelectList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.id,
          code: item.code,
          // isBind: bindData.data.includes(item.id),
        })),
      );
      return res.data.map((item: any) => ({
        ...item,
        id: item.id,
        label: item.name,
        value: item.id,
        code: item.code,
        // isBind: bindData.data.includes(item.id),
      }));
    }
  }, []);
  // 获取采集设备列表
  const getAcqDeviceListFunction = useCallback(async (params: any) => {
    const res = await getAcqDeviceList(params);
    if (res.data) {
      setAcqDeviceList(
        res.data.map((item: any) => ({
          id: item.id,
          label: item.code,
          value: item.code,
        })),
      );
    }
  }, []);

  // 获取设备分类列表
  const getDeviceClassListFunction = useCallback(async (params: any) => {
    const res = await getDeviceClassList(params);
    if (res.data) {
      setDeviceClassList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.id,
        })),
      );
      return res.data.map((item: any) => ({
        ...item,
        id: item.id,
        label: item.name,
        value: item.id,
      }));
    }
  }, []);

  // 获取设备类型列表
  const getDeviceTypeListFunction = useCallback(async (params: any) => {
    const res = await getDeviceTypeList(params);
    if (res.data) {
      setDeviceTypeList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.id,
          code: item.code,
        })),
      );
      return res.data.map((item: any) => ({
        ...item,
        id: item.id,
        label: item.name,
        value: item.id,
        code: item.code,
      }));
    }
  }, []);

  // 获取设备型号类型列表
  const getDeviceModelTypeListFunction = useCallback(async (params: any) => {
    const res = await getDeviceModelTypeList(params);
    if (res.data) {
      setDeviceModelTypeList(
        res.data.map((item: any) => ({
          id: item.id,
          label: item.factory,
          value: item.code,
        })),
      );
    }
  }, []);
  // 获取设备报文列表
  const getDevicePacketListFunction = useCallback(async (params: any) => {
    const res = await getDevicePacketList(params);
    if (res.data) {
      setDevicePacketList(
        res.data.map((item: any) => ({
          id: item.id,
          label: item.name,
          value: item.code,
        })),
      );
    }
  }, []);
  // 获取设备字段列表
  const getDeviceFieldListFunction = useCallback(async (params: any) => {
    const res = await getDeviceFieldList(params);
    if (res.data) {
      setDeviceFieldList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.field,
        })),
      );
    }
  }, []);

  // 获取在线设备列表
  const getOnlineDeviceListFunction = useCallback(async (params: any) => {
    const res = await getOnlineDeviceList(params);
    if (res.data) {
      setOnlineDeviceList(
        res.data.map((item: any) => ({
          id: item,
          label: item,
          value: item,
        })),
      );
    }
  }, []);

  // 获取指令列表
  const getInstructionListFunction = useCallback(async (params: any) => {
    const res = await getInstructionList(params);
    if (res.data) {
      setInstructionList(
        res.data.map((item: any) => ({
          id: item.id,
          label: item.cmdName,
          value: item.cmdId,
        })),
      );
    }
  }, []);

  // 获取设备指令列表
  const getDeviceCmdListFunction = useCallback(async (params: any) => {
    const res = await getDeviceCmdList(params);
    if (res.data) {
      setDeviceCmdList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.buttonName,
          value: item.id,
        })),
      );
      return res.data;
    }
  }, []);

  // 获取巡查点列表
  const getPatrolPointSelectListData = useCallback(async () => {
    const res = await getPatrolPointList();
    if (res.data) {
      setPatrolPointSelectList(
        res.data.map((item: any) => ({
          id: item.id,
          label: item.name,
          value: item.id,
        })),
      );
    }
  }, []);

  // 获取巡查任务类型列表
  const getTaskTypeSelectListData = useCallback(async () => {
    const res = await getTaskTypeList();
    if (res.data) {
      setTaskTypeSelectList(
        res.data.map((item: any) => ({
          id: item.key,
          label: item.value,
          value: item.key,
        })),
      );
    }
  }, []);

  // 获取货物列表
  const getCargoSelectListData = useCallback(async (params: any) => {
    const res = await getCargoList(params);
    if (res.data) {
      setCargoSelectList(
        res.data.map((item: any) => ({
          id: item.id,
          label: item.name,
          value: item.id,
          ...item,
        })),
      );
    }
  }, []);
  // 获取货物类型列表
  const getCargoTypeSelectListData = useCallback(async () => {
    const res = await getCargoTypeList();
    if (res.data) {
      setCargoTypeSelectList(
        res.data.map((item: any) => ({
          id: item.id,
          label: item.type,
          value: item.id,
        })),
      );
    }
  }, []);

  // 获取货物单位列表
  const getCargoUnitSelectListData = useCallback(async () => {
    const res = await getCargoUnitList();
    if (res.data) {
      setCargoUnitSelectList(
        res.data.map((item: any) => ({
          id: item.id,
          label: item.unit,
          value: item.id,
        })),
      );
    }
  }, []);

  // 获取货物入库类型列表
  const getInboundTypeSelectListData = useCallback(async (params: any) => {
    const res = await getInboundTypeList(params);
    if (res.data) {
      setInboundTypeSelectList(
        res.data.map((item: any) => ({
          id: item.id,
          label: item.type,
          value: item.id,
        })),
      );
    }
  }, []);

  // 获取货物出库类型列表
  const getOutboundTypeSelectListData = useCallback(async (params: any) => {
    const res = await getOutboundTypeList(params);
    if (res.data) {
      setOutboundTypeSelectList(
        res.data.map((item: any) => ({
          id: item.id,
          label: item.type,
          value: item.id,
        })),
      );
    }
  }, []);

  // 获取仓库树列表
  const getRepositoryTreeData = useCallback(async () => {
    const res = await getRepositoryList();
    if (res.data) {
      const repositoryTreeData: any[] = [
        {
          id: '-1',
          name: '请选择仓库',
          children: res.data,
          selectable: false,
        },
      ];
      setRepositoryTree(changeTree(repositoryTreeData, 'name', 'id', 'children'));
    }
  }, []);

  // 获取数据源列表
  const getDataSourceSelectListData = useCallback(async () => {
    const res = await getDataSourceList();
    if (res.data) {
      setDataSourceSelectList(
        res.data.map((item: any) => ({
          id: item.id,
          label: item.name,
          value: item.id,
        })),
      );
    }
  }, []);

  // 获取数据表格列表
  const getDataTableSelectListData = useCallback(async (params: any) => {
    const res = await getDataTableList(params);
    if (res.data) {
      setDataTableSelectList(
        res.data.map((item: string) => ({
          id: item,
          label: item,
          value: item,
        })),
      );
      return res.data.map((item: string) => ({
        id: item,
        label: item,
        value: item,
      }));
    } else {
      return [];
    }
  }, []);

  // 获取数据源列表
  const getDataFieldListData = useCallback(async (params: any) => {
    const res = await getDataFieldList(params);
    if (res.data) {
      setDataFieldList(
        res.data.map((item: any) => ({
          label: item,
          value: item,
        })),
      );
      return res.data.map((item: any) => ({
        label: item,
        value: item,
      }));
    }
  }, []);

  // 获取数据源列表
  const getDataFieldBySqlListData = useCallback(async (params: any) => {
    const res = await getDataFieldBySqlList(params);
    if (res.code === '0') {
      setDataFieldList(
        res.data.map((item: any) => ({
          label: item,
          value: item,
        })),
      );
      return res;
    } else {
      return res;
    }
  }, []);

  // 获取数据标签数
  const getDataTagTreeData = useCallback(async () => {
    const res = await getDataTagTree();
    if (res.data) {
      // res.data 不是数组，改一下结构
      setDataTagTree(changeTree([res.data], 'name', 'code', 'childList'));
      setDataTagTreeNode(getTreeNode([res.data], 'code', 'childList'));
    }
  }, []);

  // 获取数据中台-字段类型列表
  const getDataFieldTypeSelectListData = useCallback(async () => {
    const res = await getDataFieldTypeSelectList();
    if (res.data) {
      setDataFieldTypeSelectList(
        res.data.map((item: any) => ({
          id: item.mysqlType,
          label: item.mysqlType,
          value: item.mysqlType,
          ...item,
        })),
      );
    }
  }, []);

  // 获取数据中台-所有应用
  const getDataApplicationListData = useCallback(async (params: any) => {
    const res = await getAllApplicationList(params);
    if (res.data) {
      setDataApplicationList(
        res.data.map((item: any) => ({
          id: item.mysqlType,
          label: item.mysqlType,
          value: item.mysqlType,
          ...item,
        })),
      );
    }
  }, []);

  // 获取数据中台-所有服务
  const getDataServiceListData = useCallback(async (params: any) => {
    const res = await getAllServiceList(params);
    if (res.data) {
      setDataServiceList(
        res.data.map((item: any) => ({
          id: item.mysqlType,
          label: item.mysqlType,
          value: item.mysqlType,
          ...item,
        })),
      );
    }
  }, []);

  // 获取项目管理-项目合同列表
  const getContractListData = useCallback(async (params: any) => {
    const res = await getContractList(params);
    if (res.data) {
      setContractList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.code,
        })),
      );
    }
  }, []);

  // 获取项目管理-项目类型列表-树
  const getProjectTypeListData = useCallback(async () => {
    const res = await getProjectTypeList();
    if (res.data) {
      const treeData: any[] = [
        {
          id: '-1',
          key: '-1',
          name: '全部项目类型',
          children: res.data,
          // selectable: false,
        },
      ];
      setProjectTypeTree(changeTree(treeData, 'name', 'id', 'children'));
      setProjectType(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.code,
        })),
      );
    }
  }, []);

  // 获取项目管理-项目列表-树
  const getProjectListData = useCallback(async (params: any) => {
    const res = await getProjectList(params);
    if (res.data) {
      const treeData: any[] = [
        {
          id: '-1',
          key: '-1',
          name: '全部项目',
          children: res.data,
        },
      ];
      setProjectTree(changeTree(treeData, 'name', 'id', 'children'));
      setProject(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.code,
        })),
      );
    }
  }, []);

  // 获取项目管理-文件目录-树
  const getFileFolderListData = useCallback(async (params: any) => {
    const res = await getFileFolderList(params);
    if (res?.data) {
      const treeData: any[] = [
        {
          id: '0',
          key: '0',
          name: '根目录',
          children: res.data.folders,
          selectable: false,
        },
      ];
      setFileFolderTree(changeTree(treeData, 'name', 'id', 'children'));
      return res.data.folders;
    }
  }, []);

  // 获取项目管理-文件列表
  const getFileListData = useCallback(async (params: any) => {
    const res = await getFileFolderList(params);
    if (res.data) {
      setFileList({
        ...res.data,
        data: [
          ...res.data.files.map((item: any) => ({ ...item, type: 'file' })),
          ...res.data.folders.map((item: any) => ({ ...item, type: 'folder' })),
        ],
        total: res.data.files.length,
      });
    }
    return {
      ...res.data,
      data: [
        ...res.data.files.map((item: any) => ({ ...item, type: 'file' })),
        ...res.data.folders.map((item: any) => ({ ...item, type: 'folder' })),
      ],
      total: res.data.files.length,
    };
  }, []);

  // 获取项目管理-费用类型
  const getProjectCostTypeListData = useCallback(async (params: any) => {
    const res = await getProjectCostType(params);
    if (res.data) {
      setProjectCostTypeList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.type,
          value: item.id,
        })),
      );
    }
  }, []);

  // 获取合同下项目列表
  const getProjectByContractListData = useCallback(async (params: any) => {
    const res = await getProjectByContractList(params);
    if (res.data) {
      const projectTreeData: any[] = [
        {
          id: '-1',
          name: '所有项目',
          children: res.data,
          selectable: false,
        },
      ];
      setProjectByContract(changeTree(projectTreeData, 'name', 'id', 'children'));
      return res.data;
    }
  }, []);

  /** 获取--待办--列表 */
  const getTodoItemListData = useCallback(async (params: any) => {
    const res = await getTodoItemList(params);
    if (res.data) {
      setTodoList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.title,
          value: item.id,
        })),
      );
    }
  }, []);

  /** 获取--公告通知--列表 */
  const getBulletinItemListData = useCallback(async (params: any) => {
    const res = await getBulletinItemList(params);
    if (res.data) {
      setBulletinList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.title,
          value: item.id,
        })),
      );
    }
  }, []);
  /** 获取--报警--列表 */
  const getAlarmItemListData = useCallback(async (params: any) => {
    const res = await getAlarmItemList(params);
    if (res.data) {
      setAlarmList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.title,
          value: item.id,
        })),
      );
    }
  }, []);
  /** 获取--项目成员--列表 */
  const getProjectMemberListData = useCallback(async (params: any) => {
    const res = await getProjectMemberList(params);
    if (res.data) {
      setProjectMemberList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.member,
          value: item.id,
        })),
      );
    }
  }, []);

  return {
    gisTypeEnum,
    taskTypeEnum,
    periodEnum,
    taskStatusEnum,
    statusEnum,
    menuLayoutEnum,
    booleanStringEnum,
    booleanEnum,
    menuTypeEnum,
    sexEnum,
    deviceTypeEnum,
    deviceStatusEnum,
    deviceCmdEnum,
    deviceLocalEnum,
    emergencyLevelEnum,
    repairEnum,
    // 菜单树
    menuTree,
    menuTreeNode,
    getMenuTreeData,
    // 角色下拉
    roleSelectList,
    getRoleSelectListData,
    // 用户下拉
    userSelectList,
    getUserSelectListData,

    // 设备
    // 设备下拉 设备台账
    deviceSelectList,
    getDeviceSelectListData,
    // 采集设备下拉
    acqDeviceList,
    getAcqDeviceListFunction,
    // 设备分类下拉
    deviceClassList,
    getDeviceClassListFunction,
    // 设备类型下拉
    deviceTypeList,
    getDeviceTypeListFunction,
    // 设备型号下拉
    deviceModelTypeList,
    getDeviceModelTypeListFunction,
    // 设备报文
    devicePacketList,
    getDevicePacketListFunction,
    // 设备字段
    deviceFieldList,
    getDeviceFieldListFunction,
    // 在线设备列表
    onlineDeviceList,
    getOnlineDeviceListFunction,
    // 指令列表
    instructionList,
    getInstructionListFunction,
    // 设备指令列表
    deviceCmdList,
    getDeviceCmdListFunction,

    // 运维巡检
    // 巡查点下拉
    patrolPointSelectList,
    getPatrolPointSelectListData,
    // 巡查任务类型下拉
    taskTypeSelectList,
    getTaskTypeSelectListData,
    // 货物下拉
    cargoSelectList,
    getCargoSelectListData,
    // 货物类型下拉
    cargoTypeSelectList,
    getCargoTypeSelectListData,
    // 货物单位下拉
    cargoUnitSelectList,
    getCargoUnitSelectListData,
    // 入库类型下拉
    inboundTypeSelectList,
    getInboundTypeSelectListData,
    // 出库类型下拉
    outboundTypeSelectList,
    getOutboundTypeSelectListData,
    // 仓库下拉
    repositoryTree,
    getRepositoryTreeData,
    // 数据源下拉
    dataSourceSelectList,
    getDataSourceSelectListData,
    // 数据表格下拉
    dataTableSelectList,
    setDataTableSelectList,
    getDataTableSelectListData,
    // 数据字段下拉
    dataFieldList,
    setDataFieldList,
    getDataFieldListData,
    // sql 查询数据字段
    getDataFieldBySqlListData,
    // 数据标签树
    dataTagTree,
    dataTagTreeNode,
    getDataTagTreeData,
    // 获取数据中台-字段类型列表
    dataFieldTypeSelectList,
    getDataFieldTypeSelectListData,
    // 获取数据中台-应用列表
    dataApplicationList,
    getDataApplicationListData,
    // 获取数据中台-服务列表
    dataServiceList,
    getDataServiceListData,
    // 获取项目管理-项目合同列表
    contractList,
    getContractListData,
    // 获取项目管理-项目类型列表
    projectType,
    projectTypeTree,
    getProjectTypeListData,
    // 获取项目管理-项目列表
    project,
    projectTree,
    getProjectListData,
    // 获取项目管理-文件目录树
    fileFolderTree,
    setFileFolderTree,
    getFileFolderListData,
    // 文件列表
    fileList,
    getFileListData,
    // 项目管理-项目费用类型列表
    projectCostTypeList,
    getProjectCostTypeListData,
    // 项目管理-获取合同下项目列表
    projectByContract,
    getProjectByContractListData,
    /** 获取--待办--列表 */
    todoList,
    getTodoItemListData,
    /** 获取--公告通知--列表 */
    bulletinList,
    getBulletinItemListData,
    /** 获取--报警--列表 */
    alarmList,
    getAlarmItemListData,
    /** 获取--报警--列表 */
    projectMemberList,
    getProjectMemberListData,
  };
}
