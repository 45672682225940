import http from '@/utils/request';
/**
 *  ---公共接口---
 *  */
/** 批量上传图片 */
export async function upload(data: any) {
  return http.post({
    url: '/hohai/attach/info/upload',
    data,
  });
}

/**
 * @description 获取视频开始
 * @param deviceCode
 * @param channelId
 *
 *  */
export async function videoStartPreview(params: any) {
  return http.get({
    url: '/hohai/video/hikvision/stream/startPreview',
    params,
  });
}
/** 获取视频停止 */
export async function videoStopPreview(params: any) {
  return http.get({
    url: '/hohai/video/hikvision/stream/stopPreview',
    params,
  });
}

/**
 *  ---系统管理---
 *  */
/** 获取菜单树 */
export async function getMenuTree(params: any) {
  return http.get({ url: '/hohai/auth/menu/tree', params }).then((data: any) => {
    return data;
  });
}

/** 获取角色下拉 */
export async function getRoleList() {
  return http.get({ url: '/hohai/auth/role/itemList' }).then((data: any) => {
    return data;
  });
}

/** 获取用户下拉 */
export async function getUserList() {
  return http.get({ url: '/hohai/auth/user/itemList' }).then((data: any) => {
    return data;
  });
}

/**
 *  ---设备管理---
 *  */
/** 获取设备 台账下拉 - /device/getGisList */
export async function getDeviceList(params: any) {
  return http.get({ url: '/hohai/farmland/device/getGisList', params });
}
/** 获取采集设备下拉 */
export async function getAcqDeviceList(params: any) {
  return http.get({ url: '/hohai/farmland/device/getAcqDeviceList', params });
}

/** 获取已绑定设备下拉 */
export async function getBindDeviceList() {
  return http.get({ url: '/hohai/inspection/point/getBindDeviceList' }).then((data: any) => {
    return data;
  });
}

/** 获取设备采集型号列表--/access/model/list */
export async function getDeviceModelTypeList(params: any) {
  return http.post({ url: '/hohai/access/model/list', params });
}

/** 获取设备分类列表--/farmland/device/class/getList */
export async function getDeviceClassList(params: any) {
  return http.get({ url: '/hohai/farmland/device/class/getList', params });
}

/** 获取设备类型列表--/farmland/device/type/getList */
export async function getDeviceTypeList(params: any) {
  return http.get({ url: '/hohai/farmland/device/type/getList', params });
}

/** 获取设备报文列表--/access/packet/list */
export async function getDevicePacketList(params: any) {
  return http.post({ url: '/hohai/access/packet/config/list', params });
}

/** 获取设备字段列表--/access/data/field/list */
export async function getDeviceFieldList(params: any) {
  return http.get({ url: '/hohai/access/data/field/list', params });
}

/** 获取在线设备列表--getOnlineDeviceList */
export async function getOnlineDeviceList(params: any) {
  return http.post({ url: '/hohai/access/test/getOnlineDeviceList', params });
}

/** 获取指令列表--  /instruction/getList */
export async function getInstructionList(params: any) {
  return http.post({ url: '/hohai/farmland/instruction/getList', params });
}

/** 获取设备指令-- /device/cmd/getList */
export async function getDeviceCmdList(params: any) {
  return http.get({ url: '/hohai/farmland/device/cmd/getList', params });
}

/**
 *  ---运维巡检---
 *  */
/** 获取巡查点下拉 */
export async function getPatrolPointList() {
  return http.get({ url: '/hohai/inspection/point/list' }).then((data: any) => {
    return data;
  });
}

/** 获取任务类型 */
export async function getTaskTypeList() {
  return http.get({ url: '/hohai/inspection/subclass/list' }).then((data: any) => {
    return data;
  });
}

/**
 *  ---库存管理---
 *  */
/** 获取货物列表 */
export async function getCargoList(params: any) {
  return http.get({ url: '/hohai/repository/item/list', params }).then((data: any) => {
    return data;
  });
}

/** 获取货物类型 */
export async function getCargoTypeList() {
  return http.get({ url: '/hohai/repository/itemType/list' }).then((data: any) => {
    return data;
  });
}

/** 获取货物单位 */
export async function getCargoUnitList() {
  return http.get({ url: '/hohai/repository/unit/list' }).then((data: any) => {
    return data;
  });
}

/** 获取货物入库类型 */
export async function getInboundTypeList(params: any) {
  return http.get({ url: '/hohai/repository/inbound/type/list', params }).then((data: any) => {
    return data;
  });
}

/** 获取货物出库类型 */
export async function getOutboundTypeList(params: any) {
  return http.get({ url: '/hohai/repository/outbound/type/list', params }).then((data: any) => {
    return data;
  });
}

/** 获取仓库 */
export async function getRepositoryList() {
  return http.get({ url: '/hohai/repository/repository/list' }).then((data: any) => {
    return data;
  });
}

/**
 *  ---数据中台---
 *  */
/** 获取数据源列表 */
export async function getDataSourceList() {
  return http.post({ url: '/hohai/data/exchange/data/source/itemList' }).then((data: any) => {
    return data;
  });
}
/** 获取数据表列表 */
export async function getDataTableList(params: any) {
  return http.get({ url: '/hohai/data/exchange/metadata/getTables', params }).then((data: any) => {
    return data;
  });
}

/** 获取新数据表列表 */
export async function getNewDataTableList(params: any) {
  return http
    .get({ url: '/hohai/data/platform/metadata/getTableNames', params })
    .then((data: any) => {
      return data;
    });
}
/** 获取数据字段列表 */
export async function getDataFieldList(params: any) {
  return http.get({ url: '/hohai/data/exchange/metadata/getColumns', params }).then((data: any) => {
    return data;
  });
}

/** 获取数据字段列表--sql */
export async function getDataFieldBySqlList(params: any) {
  return http
    .get({ url: '/hohai/data/exchange/metadata/getColumnsByQuerySql', params })
    .then((data: any) => {
      return data;
    });
}

/** 获取数据标签数 */
export async function getDataTagTree() {
  return http.post({ url: '/hohai/data/platform/asset/label/getTree' });
}

/** 获取-字段类型列表 */
export async function getDataFieldTypeSelectList() {
  return http.get({ url: '/hohai/data/platform/asset/field/getTypeList' });
}

/** 获取所有应用 */
export async function getAllApplicationList(params: any) {
  return http.post({
    url: `/hohai/data/platform/app/info/getList`,
    params,
  });
}

/** 获取所有服务 */
export async function getAllServiceList(params: any) {
  return http.post({
    url: `/hohai/data/platform/api/info/getList`,
    params,
  });
}

/**
 *  ---项目管理---
 *  */
/** 获取项目合同下拉 */
export async function getContractList(params: any) {
  return http.get({ url: '/hohai/project/contract/list', params });
}

/** 获取 项目类型List */
export async function getProjectTypeList() {
  return http.get({ url: '/hohai/project/management/listWithTemp' });
}

/** 获取 项目List  /project/list*/
export async function getProjectList(params: any) {
  return http.get({ url: '/hohai/project/project/list', params });
}

/** 获取 项目费用类型List */
export async function getProjectCostType(params: any) {
  return http.get({ url: '/hohai/project/costType/list', params });
}

/** 获取合同下项目列表 */
export async function getProjectByContractList(params: any) {
  return http.get({ url: `/hohai/project/contract/projectsByContractId`, params });
}

/** 获取--项目成员--列表 */
export async function getProjectMemberList(params: any) {
  return http.get({ url: `/hohai/project/project/memberList`, params });
}

/**
 *  ---文件管理---
 *  */
/** 获取菜单树 --/file/list */
export async function getFileFolderList(params: any) {
  return http.get({ url: `/hohai/project/file/list`, params });
}

/**
 *  ---消息中心---
 *  */
/** 获取--待办--列表 */
export async function getTodoItemList(params: any) {
  return http.get({ url: `/hohai/message/todo/itemList`, params });
}

/** 获取--公告通知--列表 */
export async function getBulletinItemList(params: any) {
  return http.get({ url: `/hohai/message/bulletin/itemList`, params });
}

/** 获取--报警--列表 */
export async function getAlarmItemList(params: any) {
  return http.get({ url: `/hohai/message/alarm/itemList`, params });
}
