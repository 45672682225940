/**
 * @see https://umijs.org/zh-CN/plugins/plugin-access
 * */

import { changeMenuList } from '@/utils/utils';

export default function (initialState: { currentUser?: API.CurrentUser | undefined }) {
  const { currentUser } = initialState || {};

  // const initAdminMenuList: string[] = ['系统管理', '用户管理', '角色管理', '菜单管理'];
  const initAdminMenuList: string[] = ['/system', '/system/user', '/system/role', '/system/menu'];
  return {
    canView: (route: any) => {
      if (currentUser?.data?.menuTree?.length) {
        return changeMenuList(currentUser.data.menuTree).flat(Infinity).includes(route.path);
      } else if (currentUser?.data?.isAdmin) {
        return initAdminMenuList.includes(route.path);
      } else {
        return false;
      }
    },
    // 管理员
    // admin: true,
    admin: currentUser?.data?.roleCodes?.includes('admin'),

    // 仓库管理员 repository_admin
    repository_admin:
      (currentUser?.data?.roleCodes?.includes('repository_admin') &&
        !currentUser?.data?.roleCodes?.includes('admin')) ||
      false,

    // 项目管理-项目负责人 project_leader
    project_leader:
      (currentUser?.data?.roleCodes?.includes('project_leader') &&
        !currentUser?.data?.roleCodes?.includes('admin')) ||
      false,
    // 项目管理-资料员 clerk
    clerk:
      (currentUser?.data?.roleCodes?.includes('clerk') &&
        !currentUser?.data?.roleCodes?.includes('admin')) ||
      false,
    // 项目管理-财务 accountant
    accountant:
      (currentUser?.data?.roleCodes?.includes('accountant') &&
        !currentUser?.data?.roleCodes?.includes('admin')) ||
      false,
    // 项目管理-采购 purchase
    purchase:
      (currentUser?.data?.roleCodes?.includes('purchase') &&
        !currentUser?.data?.roleCodes?.includes('admin')) ||
      false,
    // 项目管理-合同管理员 contractAdmin
    contractAdmin:
      (currentUser?.data?.roleCodes?.includes('contractAdmin') &&
        !currentUser?.data?.roleCodes?.includes('admin')) ||
      false,
    // 项目管理-总经理 manager
    manager:
      (currentUser?.data?.roleCodes?.includes('manager') &&
        !currentUser?.data?.roleCodes?.includes('admin')) ||
      false,
    // 智慧灌区 farmland
    farmland:
      (currentUser?.data?.roleCodes?.includes('farm') &&
        !currentUser?.data?.roleCodes?.includes('admin')) ||
      false,
  };
}
