// export default () => '这是巡查日历';

import { useCallback, useState } from 'react';

import {
  getDataSourceList,
  getDataTableList,
  getNewDataTableList,
  getDataFieldList,
  getNewDataFieldList,
  getDataFieldBySqlList,
  getDataTagTree,
  getDataFieldTypeSelectList,
  getAllApplicationList,
  getAllServiceList,
} from '../services/DataCenter';
import { getTreeNode, changeTreeNew } from '@/utils/utils';

import type { DefaultOptionType } from 'antd/lib/select';
import type { DataNode } from 'antd/es/tree';

export default function useDataCenterM() {
  // 数据库
  const databaseEnum: DefaultOptionType[] = [
    { id: '1', value: 'MYSQL', label: 'MYSQL' },
    { id: '2', value: 'SQLSERVER', label: 'SQLSERVER' },
    { id: '3', value: 'ORACLE', label: 'ORACLE' },
  ];

  // 路由策略
  const routingPolicyEnum: DefaultOptionType[] = [
    { id: '1', value: 'RANDOM', label: '随机' },
    { id: '2', value: 'FIRST', label: '第一个' },
  ];

  // 数据类型
  const dataTypeEnum: DefaultOptionType[] = [
    { id: '1', value: 'String', label: '字符串' },
    { id: '2', value: 'Integer', label: '整数' },
    { id: '3', value: 'Float', label: '浮点' },
    { id: '4', value: 'Boolean', label: '布尔' },
    { id: '5', value: 'List', label: '列表' },
  ];

  const [dataSourceSelectList, setDataSourceSelectList] = useState<DefaultOptionType[]>([]); // 数据源列表
  const [dataTableSelectList, setDataTableSelectList] = useState<DefaultOptionType[]>([]); // 数据表格列表
  const [newDataTableSelectList, setNewDataTableSelectList] = useState<DefaultOptionType[]>([]); // 数据表格列表
  const [dataFieldList, setDataFieldList] = useState<API.CheckboxItem[]>([]); // 数据字段列表
  const [newDataFieldList, setNewDataFieldList] = useState<API.CheckboxItem[]>([]); // 数据字段列表
  const [dataTagTree, setDataTagTree] = useState<DataNode[]>([]); // 数据标签树
  const [dataTagTreeNode, setDataTagTreeNode] = useState<string[]>([]); // 数据标签节点
  const [dataFieldTypeSelectList, setDataFieldTypeSelectList] = useState<DefaultOptionType[]>([]); // 数据中台-字段类型
  const [dataApplicationList, setDataApplicationList] = useState<DefaultOptionType[]>([]); // 数据中台-所有应用
  const [dataServiceList, setDataServiceList] = useState<DefaultOptionType[]>([]); // 数据中台-所有服务

  // 获取数据源列表
  const getDataSourceSelectListData = useCallback(async () => {
    const res = await getDataSourceList();
    if (res.data) {
      setDataSourceSelectList(
        res.data.map((item: any) => ({
          id: item.id,
          label: item.name,
          value: item.id,
        })),
      );
      return res.data.map((item: any) => ({
        ...item,
        id: item.id,
        label: item.name,
        value: item.id,
      }));
    }
  }, []);

  // 获取数据表格列表
  const getDataTableSelectListData = useCallback(async (params: any) => {
    const res = await getDataTableList(params);
    if (res.data) {
      setDataTableSelectList(
        res.data.map((item: string) => ({
          id: item,
          label: item,
          value: item,
        })),
      );
      return res.data.map((item: string) => ({
        id: item,
        label: item,
        value: item,
      }));
    } else {
      return [];
    }
  }, []);

  // 获取新数据表格列表
  const getNewDataTableSelectListData = useCallback(async (params: any) => {
    const res = await getNewDataTableList(params);
    if (res.data) {
      setNewDataTableSelectList(
        res.data.map((item: string) => ({
          id: item,
          label: item,
          value: item,
        })),
      );
      return res.data.map((item: string) => ({
        id: item,
        label: item,
        value: item,
      }));
    } else {
      return [];
    }
  }, []);

  // 获取数据字段列表
  const getDataFieldListData = useCallback(async (params: any) => {
    const res = await getDataFieldList(params);
    if (res.data) {
      setDataFieldList(
        res.data.map((item: any) => ({
          label: item,
          value: item,
        })),
      );
      return res.data.map((item: any) => ({
        label: item,
        value: item,
      }));
    }
  }, []);

  // 获取 --新-- 数据字段列表
  const getNewDataFieldListData = useCallback(async (params: any) => {
    const res = await getNewDataFieldList(params);
    if (res.data) {
      setNewDataFieldList(
        res.data.map((item: any) => ({
          ...item,
          label: item.fieldName,
          value: item.id,
        })),
      );
      return res.data.map((item: any) => ({
        ...item,
        label: item.fieldName,
        value: item.id,
      }));
    }
  }, []);

  // 获取数据源列表
  const getDataFieldBySqlListData = useCallback(async (params: any) => {
    const res = await getDataFieldBySqlList(params);
    if (res.code === '0') {
      setDataFieldList(
        res.data.map((item: any) => ({
          label: item,
          value: item,
        })),
      );
      return res;
    } else {
      return res;
    }
  }, []);

  // 获取数据标签数
  const getDataTagTreeData = useCallback(async () => {
    const res = await getDataTagTree();
    if (res.data) {
      // res.data 不是数组，改一下结构
      setDataTagTree(changeTreeNew(res.data.childList, 'name', 'code', 'childList') || []);
      setDataTagTreeNode(getTreeNode(res.data.childList, 'code', 'childList'));
    }
  }, []);

  // 获取数据中台-字段类型列表
  const getDataFieldTypeSelectListData = useCallback(async () => {
    const res = await getDataFieldTypeSelectList();
    if (res.data) {
      setDataFieldTypeSelectList(
        res.data.map((item: any) => ({
          id: item.mysqlType,
          label: item.mysqlType,
          value: item.mysqlType,
          ...item,
        })),
      );
    }
  }, []);

  // 获取数据中台-所有应用
  const getDataApplicationListData = useCallback(async (params: any) => {
    const res = await getAllApplicationList(params);
    if (res.data) {
      setDataApplicationList(
        res.data.map((item: any) => ({
          id: item.mysqlType,
          label: item.mysqlType,
          value: item.mysqlType,
          ...item,
        })),
      );
    }
  }, []);

  // 获取数据中台-所有服务
  const getDataServiceListData = useCallback(async (params: any) => {
    const res = await getAllServiceList(params);
    if (res.data) {
      setDataServiceList(
        res.data.map((item: any) => ({
          id: item.mysqlType,
          label: item.mysqlType,
          value: item.mysqlType,
          ...item,
        })),
      );
    }
  }, []);

  return {
    databaseEnum,
    routingPolicyEnum,
    dataTypeEnum,

    // 数据源下拉
    dataSourceSelectList,
    getDataSourceSelectListData,
    // 数据表格下拉
    dataTableSelectList,
    setDataTableSelectList,
    getDataTableSelectListData,
    // 新--数据表格下拉
    newDataTableSelectList,
    getNewDataTableSelectListData,
    // 数据字段下拉
    dataFieldList,
    setDataFieldList,
    getDataFieldListData,
    // 新--数据字段下拉
    newDataFieldList,
    getNewDataFieldListData,
    // sql 查询数据字段
    getDataFieldBySqlListData,
    // 数据标签树
    dataTagTree,
    dataTagTreeNode,
    getDataTagTreeData,
    // 获取数据中台-字段类型列表
    dataFieldTypeSelectList,
    getDataFieldTypeSelectListData,
    // 获取数据中台-应用列表
    dataApplicationList,
    getDataApplicationListData,
    // 获取数据中台-服务列表
    dataServiceList,
    getDataServiceListData,
  };
}
