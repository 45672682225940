import { getToken } from '@/utils/utils';

const { NODE_ENV } = process.env;

const socket = {
  // websocketUrl: 'ws://58.210.224.230:8888/ws', // socket监听地址--实验室
  // websocketUrl: 'ws://116.62.36.9:8888/ws', // socket监听地址--新阿里云
  websocketUrl:
    NODE_ENV === 'development' ? 'ws://116.62.36.9/ws' : `ws://${window.location.hostname}/ws`,
  // websocketUrl: 'ws://10.6.227.85:8888/ws', // socket监听地址
  websocket: null, // websocket监听对象
  isWebSocket: false, // 是否连接
  websocketInterval: null,

  // 建立连接
  created: (option) => {
    socket.initWebSocket(option);
  },

  // 断开连接
  destroyed: () => {
    if (socket && socket.websocket) {
      socket.websocket.close(); //离开路由之后断开websocket连接
    }
  },

  // 初始化socket信息
  initWebSocket: (option) => {
    const { onMessage, sendMessage, onError, onClose, id } = option || {};
    //初始化weosocket
    // 取用户信息，作为socketid
    // let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    // if (!currentUser) return;
    // socket信息
    // socket.websocket = new WebSocket(socket.websocketUrl + (id || currentUser.id));
    socket.websocket = new WebSocket(socket.websocketUrl);
    socket.websocket.onmessage = onMessage || socket.websocketonmessage;
    socket.websocket.sendMessage = sendMessage || socket.websocketsend;
    socket.websocket.onopen = socket.websocketonopen;
    socket.websocket.onclose = onClose || socket.websocketonclose;
    socket.websocket.onerror = onError || socket.websocketonerror;
  },

  // 监听socket连接成功信息
  websocketonopen: () => {
    //连接建立之后执行send方法发送数据
    socket.isWebSocket = true;
    console.log('链接成功！');
    socket.websocketsend({ socketType: '1', token: getToken() });
    socket.websocketInterval = setInterval(() => {
      socket.websocketsend({ socketType: '1', token: getToken() });
    }, 100000);
  },

  // 监听socket连接关闭信息
  websocketonclose: () => {
    console.log('链接关闭！');
  },

  // socket连接失败重新建立连接
  websocketonerror: () => {
    //连接建立失败重连
    socket.initWebSocket();
  },

  // 监听接收消息
  websocketonmessage: (e) => {
    //数据接收
    console.log('redata', e.data);
  },

  // 发送消息
  websocketsend: (data) => {
    //数据发送
    // 如果未建立连接，则建立连接
    if (socket.isWebSocket) {
      console.log('发送消息!');
      socket.websocket.send(JSON.stringify(data));
    } else {
      console.log('请建立连接!');
    }
  },

  // 关闭连接
  websocketclose: (e) => {
    //关闭
    console.log('关闭连接!');
    if (socket && socket.websocket) {
      clearInterval(socket.websocketInterval);
      socket.websocket.close();
    }
    socket.isWebSocket = false;
  },
};
export default socket;
