// export default () => '这是巡查日历';

import { useCallback, useState } from 'react';

// 高标准农田
import {
  getIrrigationList,
  getPlantingList,
  getPlantingByPumpRoomList,
  getIrrigationSysByPumpRoomId,
  getPlantingCropList,
  getIrrigationSystemList,
  getPumpRoomList,
  getPumpRoomDeviceList,
  getPumpRoomSiteList,
  getGateSiteList,
  getCropList,
  getPipeList,
  getSiteTypeList,
  getSiteList,
  getFarmerList,
  getCanalList,
  getInstructionList,
  getPurchaserList,
  getNodeList,
  getPatrolPointList,
  getTaskTypeList,
} from '../services/Farmland';

import { addTreeRoot } from '@/utils/utils';

import WEATHER_STATION from '../../public/farmland/WEATHER_STATION.png';
import SOIL_MOISTURE_STATION from '../../public/farmland/SOIL_MOISTURE_STATION.png';
import INSECT_STATION from '../../public/farmland/INSECT_STATION.png';
import VALVE_STATION from '../../public/farmland/VALVE_STATION.png';
import GATE_STATION from '../../public/farmland/GATE_STATION.png';
import HYDRO_JUNCTION_STATION from '../../public/farmland/HYDRO_JUNCTION_STATION.png';
import WATER_QUALITY_MONITORING_POINT from '../../public/farmland/WATER_QUALITY_MONITORING_POINT.png';
import VIDEO_STATION from '../../public/farmland/VIDEO_STATION.png';
import SAFETY_MONITORING_STATION from '../../public/farmland/SAFETY_MONITORING_STATION.png';
import PUMP_ROOM_STATION from '../../public/farmland/PUMP_ROOM_STATION.png';

import type { DefaultOptionType } from 'antd/lib/select';
import type { DataNode } from 'antd/es/tree';

const coordinatesType = 'bd09';

export default function useFarmlandM() {
  const systemTypeEnums: DefaultOptionType[] = [
    { id: '1', value: 'TUBE_IRRIGATION', label: '管灌' },
    { id: '2', value: 'CANAL_IRRIGATION', label: '渠灌' },
  ];
  const elementTypeEnums: DefaultOptionType[] = [
    { id: '1', value: 'ROOT_PIPE', label: '主干管' },
    { id: '2', value: 'MAIN_PIPE', label: '干管' },
    { id: '3', value: 'BRANCH_PIPE', label: '支管' },
    { id: '4', value: 'WATER_PUMP', label: '水泵' },
    { id: '5', value: 'VALVE', label: '阀门' },
    { id: '5', value: 'GATE', label: '闸门' },
  ];
  const nodeTypeEnums: DefaultOptionType[] = [
    { id: '1', value: 'RESERVOIR', label: '水库' },
    { id: '2', value: 'POOL', label: '水池' },
    { id: '3', value: 'NODE', label: '节点' },
  ];

  const missionEvaluationEnums: DefaultOptionType[] = [
    { id: '1', value: 'MISSION_EVALUATION_EXCELLENT', label: '优秀' },
    { id: '2', value: 'MISSION_EVALUATION_GOOD', label: '良好' },
    { id: '3', value: 'MISSION_EVALUATION_QUALIFIED', label: '合格' },
  ];

  // 任务状态
  const taskStatusEnum: DefaultOptionType[] = [
    { id: '1', value: 'MISSION_STATUS_NOT_STARTED', label: '未处理', color: '#D9001B' },
    { id: '2', value: 'MISSION_STATUS_IN_PROGRESS', label: '进行中', color: '#1890ff' },
    { id: '3', value: 'MISSION_STATUS_INCOMPLETE', label: '未处理', color: '#D9001B' },
    { id: '4', value: 'MISSION_STATUS_COMPLETED', label: '已处理', color: '#3FC57F' },
  ];
  // 策略状态类型
  const strategyStatusEnum = [
    { id: 'EXECUTE', value: 'EXECUTE', label: '已执行' },
    { id: 'TO_BE_IMPLEMENTED', value: 'TO_BE_IMPLEMENTED', label: '待执行' },
    { id: 'ABNORMAL', value: 'ABNORMAL', label: '异常' },
    { id: 'IN_EXECUTION', value: 'IN_EXECUTION', label: '执行中' },
    { id: 'VOID', value: 'VOID', label: '作废' },
    { id: 'PAUSE', value: 'PAUSE', label: '暂停' },
  ];
  // 节点类型
  const nodeTypeEnum = [
    { id: 'WATER_PUMP', value: 'WATER_PUMP', label: '水泵' },
    { id: 'VALVE', value: 'VALVE', label: '阀门' },
    { id: 'NODE', value: 'NODE', label: '节点' },
  ];
  // 作物等级
  const cropLevelEnum: DefaultOptionType[] = [
    { id: '1', value: '特级', label: '特级' },
    { id: '2', value: '一级', label: '一级' },
    { id: '3', value: '二级', label: '二级' },
    { id: '4', value: '三级', label: '三级' },
  ];
  // 采收规格 （采收作物单位）
  const cropUnitEnum: DefaultOptionType[] = [
    { id: '1', value: '斤', label: '斤' },
    { id: '2', value: '公斤', label: '公斤' },
    { id: '3', value: 'KG', label: 'KG' },
    { id: '4', value: '吨', label: '吨' },
  ];
  // 作业类型
  const jobTypeEnum: DefaultOptionType[] = [
    { id: '1', value: 'FERTILIZATION', label: '施肥' },
    { id: '2', value: 'IRRIGATION', label: '灌溉' },
  ];
  // 渠系类型
  const canalTypeEnum: DefaultOptionType[] = [
    { id: '1', value: 'MAIN_CANAL', label: '干渠' },
    { id: '2', value: 'BRANCH_CANAL', label: '支渠' },
    { id: '3', value: 'BUCKET_CANAL', label: '斗渠' },
  ];

  // 渠系等级
  const pipeLevelEnum: DefaultOptionType[] = [
    { id: '0', value: '0', label: '0' },
    { id: '1', value: '1', label: '1' },
    { id: '2', value: '2', label: '2' },
    { id: '3', value: '3', label: '3' },
  ];

  // 区域
  const areaEnum: DefaultOptionType[] = [
    { id: 'BACKBONE_ENGINEERING', value: 'BACKBONE_ENGINEERING', label: '骨干工程' },
    { id: 'FIELD', value: 'FIELD', label: '田间' },
  ];

  // 站点类型
  const siteTypeEnum: DefaultOptionType[] = [
    { id: '1', value: 'WEATHER_STATION', label: '气象站', icon: WEATHER_STATION },
    { id: '2', value: 'SOIL_MOISTURE_STATION', label: '墒情站', icon: SOIL_MOISTURE_STATION },
    { id: '3', value: 'INSECT_STATION', label: '虫情站', icon: INSECT_STATION },
    { id: '4', value: 'VALVE_STATION', label: '阀门站', icon: VALVE_STATION },
    { id: '5', value: 'GATE_STATION', label: '闸门站', icon: GATE_STATION },
    { id: '6', value: 'HYDRO_JUNCTION_STATION', label: '水利枢纽站', icon: HYDRO_JUNCTION_STATION },
    {
      id: '7',
      value: 'WATER_QUALITY_MONITORING_POINT',
      label: '水质监测点',
      icon: WATER_QUALITY_MONITORING_POINT,
    },
    { id: '8', value: 'VIDEO_STATION', label: '视频站', icon: VIDEO_STATION },
    {
      id: '9',
      value: 'SAFETY_MONITORING_STATION',
      label: '安全监测站',
      icon: SAFETY_MONITORING_STATION,
    },
    {
      id: '10',
      value: 'PIPE_NETWORK_MONITORING',
      label: '管网监控',
      icon: WEATHER_STATION,
    },
    { id: '11', value: 'PUMP_ROOM_STATION', label: '首部站', icon: PUMP_ROOM_STATION },
  ];

  /**
   * 信息化监控 展示字段
   * getFields: image.png
   */
  const monitorFields = {
    kqwd: 'kqwd',
    xdsd: 'xdsd',
    dqyl: 'dqyl',
    dqyq: 'dqyl',
    fs: 'fs',
    fx: 'fx',
    jyl: 'jyl',
    rzfs: 'rzfs',
    gzd: 'gzd',
    ET0: 'ETo',
    ETo: 'ETo',
    eto: 'ETo',
    ssll: 'ssll',
    dqsw: 'dqsw',
    tcwd: 'tcwd',
    tcsd: 'tcsd',
    tcEC: 'tcEC',
    hczd: 'hczd',
    hczs: 'hczs',
  };

  // 高标准农田
  const [irrigationList, setIrrigationList] = useState<DefaultOptionType[]>([]); // 灌区列表
  const [irrigationTree, setIrrigationTree] = useState<DataNode[]>([]); // 灌区树
  const [plantingList, setPlantingList] = useState<DefaultOptionType[]>([]); // 种植单元列表
  const [plantingListByPump, setPlantingListByPump] = useState<DefaultOptionType[]>([]); // 种植单元列表ByPump
  const [irrigationSysByPump, setIrrigationSysByPump] = useState<DefaultOptionType[]>([]); // 种植单元列表ByPump
  const [plantingCropList, setPlantingCropList] = useState<DefaultOptionType[]>([]); // 种植单元作物列表
  const [irrigationSystemList, setIrrigationSystemList] = useState<DefaultOptionType[]>([]); // 灌溉系统列表
  const [cropList, setCropList] = useState<DefaultOptionType[]>([]); // 作物列表
  const [pumpRoomList, setPumpRoomList] = useState<DefaultOptionType[]>([]); // 首部泵房列表
  const [pumpRoomSiteList, setPumpRoomSiteList] = useState<DefaultOptionType[]>([]); // 泵房站点列表
  const [gateSiteList, setGateSiteList] = useState<DefaultOptionType[]>([]); // 闸站站点列表
  const [pumpRoomDeviceList, setPumpRoomDeviceList] = useState<DefaultOptionType[]>([]); // 首部泵房设备列表
  const [pipeList, setPipeList] = useState<DefaultOptionType[]>([]); // 管网列表
  const [siteTypeList, setSiteTypeList] = useState<DefaultOptionType[]>([]); // 站点列表
  const [siteList, setSiteList] = useState<DefaultOptionType[]>([]); // 站点列表
  const [farmerList, setFarmerList] = useState<DefaultOptionType[]>([]); // 灌区用户列表
  const [canalList, setCanalList] = useState<DefaultOptionType[]>([]); // 渠系列表
  const [instructionList, setInstructionList] = useState<DefaultOptionType[]>([]); // 指令集列表
  const [purchaserList, setPurchaserList] = useState<DefaultOptionType[]>([]); // 采购商列表
  const [nodeList, setNodeList] = useState<DefaultOptionType[]>([]); // 节点列表
  // 运维巡检
  const [patrolPointSelectList, setPatrolPointSelectList] = useState<DefaultOptionType[]>([]); // 巡查点列表

  // 灌区列表/树
  const getIrrigationListFunction = useCallback(async (params: any) => {
    const res = await getIrrigationList({ ...params, coordinatesType: coordinatesType });
    if (res.data) {
      setIrrigationTree(
        addTreeRoot(
          res.data.map((item: any) => ({
            ...item,
            id: item.id,
            title: item.name,
            key: item.id,
          })),
          '全部灌区',
          '-1',
          true,
        ),
      );
      setIrrigationList(
        res.data.map((item: any) => ({ ...item, id: item.id, label: item.name, value: item.id })),
      );
      return res.data;
    }
  }, []);

  // 种植单元列表
  const getPlantingListFunction = useCallback(async (params: any) => {
    const res = await getPlantingList({ ...params, coordinatesType: coordinatesType });
    if (res.data) {
      setPlantingList(
        res.data.map((item: any) => ({ ...item, id: item.id, label: item.name, value: item.id })),
      );
      return res.data.map((item: any) => ({
        ...item,
        id: item.id,
        label: item.name,
        value: item.code,
        code: item.code,
      }));
    }
  }, []);

  // 种植单元列表ByPump
  const getPlantingListByPumpFunction = useCallback(async (params: any) => {
    const res = await getPlantingByPumpRoomList({ ...params, coordinatesType: coordinatesType });
    if (res.data) {
      setPlantingListByPump(
        res.data.map((item: any) => ({ ...item, id: item.id, label: item.name, value: item.id })),
      );
      return res.data.map((item: any) => ({
        ...item,
        id: item.id,
        label: item.name,
        value: item.code,
      }));
    }
  }, []);

  // 系统列表ByPump
  const getIrrigationSysByPumpFunction = useCallback(async (params: any) => {
    const res = await getIrrigationSysByPumpRoomId({ ...params, coordinatesType: coordinatesType });
    if (res.data) {
      setIrrigationSysByPump(
        res.data.map((item: any) => ({ ...item, id: item.id, label: item.name, value: item.id })),
      );
      return res.data.map((item: any) => ({
        ...item,
        id: item.id,
        label: item.name,
        value: item.code,
      }));
    }
  }, []);

  // 种植单元作物列表
  const getPlantingCropListFunction = useCallback(async (params: any) => {
    const res = await getPlantingCropList({ ...params, coordinatesType: coordinatesType });
    if (res.data) {
      setPlantingCropList(
        res.data.map((item: any) => ({ ...item, id: item.id, label: item.name, value: item.code })),
      );
      return res.data.folders;
    }
  }, []);

  // 灌溉系统列表
  const getIrrigationSystemListFunction = useCallback(async (params: any) => {
    const res = await getIrrigationSystemList({ ...params, coordinatesType: coordinatesType });
    if (res.data) {
      setIrrigationSystemList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: `${item.name}（${
            systemTypeEnums.filter((e) => e.value === item.type)[0]?.label || ''
          }）`,
          value: item.id,
          code: item.code,
        })),
      );
      return res.data;
    }
  }, []);

  // 作物列表
  const getCropListFunction = useCallback(async (params: any) => {
    const res = await getCropList({ ...params, coordinatesType: coordinatesType });
    if (res.data) {
      setCropList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          code: item.code,
          value: item.id,
        })),
      );
      return res.data.folders;
    }
  }, []);

  // 首部泵房列表
  const getPumpRoomListFunction = useCallback(async (params: any) => {
    const res = await getPumpRoomList({ ...params, coordinatesType: coordinatesType });
    if (res.data) {
      setPumpRoomList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.id,
          code: item.code,
          // value: item.code,
        })),
      );
      return res.data.folders;
    }
  }, []);

  // 首部泵房设备列表
  const getPumpRoomDeviceListFunction = useCallback(async (params: any) => {
    const res = await getPumpRoomDeviceList(params);
    if (res.data) {
      setPumpRoomDeviceList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.deviceName,
          code: item.deviceCode,
          value: item.id,
        })),
      );
      return res.data.folders;
    }
  }, []);

  // 泵房站点列表
  const getPumpRoomSiteListFunction = useCallback(async (params: any) => {
    const res = await getPumpRoomSiteList({ ...params, coordinatesType: coordinatesType });
    if (res.data) {
      setPumpRoomSiteList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.id,
          code: item.code,
          // value: item.code,
        })),
      );
      return res.data;
    }
  }, []);
  // 闸站站点列表
  const getGateSiteListFunction = useCallback(async (params: any) => {
    const res = await getGateSiteList({ ...params, coordinatesType: coordinatesType });
    if (res.data) {
      setGateSiteList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.id,
          code: item.code,
          // value: item.code,
        })),
      );
      return res.data;
    }
  }, []);

  // 管网列表
  const getPipeListFunction = useCallback(async (params: any) => {
    const res = await getPipeList({ ...params, coordinatesType: coordinatesType });
    if (res.data) {
      setPipeList(
        res.data.map((item: any) => ({ ...item, id: item.id, label: item.name, value: item.code })),
      );
      return res.data;
    }
  }, []);

  // 站点列表
  const getSiteTypeListFunction = useCallback(async (params: any) => {
    const res = await getSiteTypeList({ ...params });
    if (res.data) {
      setSiteTypeList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.id,
          code: item.code,
        })),
      );
      return res.data;
    }
  }, []);

  // 站点列表
  const getSiteListFunction = useCallback(async (params: any) => {
    const res = await getSiteList({ ...params, coordinatesType: coordinatesType });
    if (res.data) {
      setSiteList(
        res.data.map((item: any) => ({
          ...item,
          id: item.siteInfo.id,
          label: item.siteInfo.siteName,
          value: item.siteInfo.id,
          code: item.siteInfo.siteCode,
        })),
      );
      return res.data;
    }
  }, []);

  // 获取灌区用户列表
  const getFarmerListFunction = useCallback(async (params: any) => {
    const res = await getFarmerList({ ...params });
    if (res.data) {
      setFarmerList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.id,
          code: item.code,
        })),
      );
      return res.data;
    }
  }, []);

  // 渠系列表
  const getCanalListFunction = useCallback(async (params: any) => {
    const res = await getCanalList({ ...params, coordinatesType: coordinatesType });
    if (res.data) {
      setCanalList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.id,
          code: item.code,
        })),
      );
      return res;
    }
  }, []);

  // 指令集列表
  const getInstructionListFunction = useCallback(async (params: any) => {
    const res = await getInstructionList({ ...params, coordinatesType: coordinatesType });
    if (res.data) {
      setInstructionList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.id,
        })),
      );
      return res;
    }
  }, []);

  // 供应商列表
  const getPurchaserListFunction = useCallback(async (params: any) => {
    const res = await getPurchaserList({ ...params });
    if (res.data) {
      setPurchaserList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.id,
        })),
      );
      return res;
    }
  }, []);

  // 节点列表
  const getNodeListFunction = useCallback(async (params: any) => {
    const res = await getNodeList({ ...params });
    if (res.data) {
      setNodeList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.code,
          code: item.code,
        })),
      );
      return res;
    }
  }, []);

  // 获取巡查点列表
  const getPatrolPointSelectListData = useCallback(async () => {
    const res = await getPatrolPointList();
    if (res.data) {
      setPatrolPointSelectList(
        res.data.map((item: any) => ({
          id: item.id,
          label: item.name,
          value: item.id,
        })),
      );
    }
  }, []);

  return {
    /**
     * 高标准农田
     */
    systemTypeEnums,
    elementTypeEnums,
    nodeTypeEnums,
    missionEvaluationEnums,
    taskStatusEnum,
    strategyStatusEnum,
    nodeTypeEnum,
    cropUnitEnum,
    cropLevelEnum,
    jobTypeEnum,
    areaEnum,
    pipeLevelEnum,
    canalTypeEnum,
    siteTypeEnum,
    monitorFields,
    /** 获取灌区树列表 */
    irrigationList,
    irrigationTree,
    getIrrigationListFunction,
    /** 获取种植单元列表 */
    plantingList,
    getPlantingListFunction,
    /** 获取种植单元列表ByPump */
    plantingListByPump,
    getPlantingListByPumpFunction,
    /** 获取系统列表ByPump */
    irrigationSysByPump,
    getIrrigationSysByPumpFunction,
    /** 获取灌溉系统列表 */
    irrigationSystemList,
    getIrrigationSystemListFunction,
    /** 获取种植单元作物列表 */
    plantingCropList,
    getPlantingCropListFunction,
    /** 获取作物列表 */
    cropList,
    getCropListFunction,
    /** 获取首部泵房列表 */
    pumpRoomList,
    getPumpRoomListFunction,
    /** 获取泵房站点列表 */
    pumpRoomSiteList,
    getPumpRoomSiteListFunction,
    /** 获取闸站站点列表 */
    gateSiteList,
    getGateSiteListFunction,
    /** 获取首部泵房设备列表 */
    pumpRoomDeviceList,
    getPumpRoomDeviceListFunction,
    /** 获取管网列表 */
    pipeList,
    getPipeListFunction,
    /** 获取站点类型列表 */
    siteTypeList,
    getSiteTypeListFunction,
    /** 获取站点列表 */
    siteList,
    getSiteListFunction,
    /** 获取灌区用户 */
    farmerList,
    getFarmerListFunction,
    /** 获取渠系 */
    canalList,
    getCanalListFunction,
    /** 获取指令集 */
    instructionList,
    getInstructionListFunction,
    /** 获取供应商 */
    purchaserList,
    getPurchaserListFunction,
    /** 获取节点 */
    nodeList,
    getNodeListFunction,
    // 运维巡检
    // 巡查点下拉
    patrolPointSelectList,
    getPatrolPointSelectListData,
  };
}
