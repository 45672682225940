import React, { useCallback } from 'react';
import { LogoutOutlined, SettingOutlined, MessageOutlined } from '@ant-design/icons';
import { Avatar, Spin } from 'antd';
import { history, useModel } from 'umi';
import HeaderDropdown from '../HeaderDropdown';
import styles from './index.less';
import { outLogin } from '@/pages/user/Login/service';
import type { MenuInfo } from 'rc-menu/lib/interface';
import type { MenuProps } from 'antd';
import { removeToken, removeUserInfo } from '@/utils/utils';
import Socket from '@/utils/socket';

export type GlobalHeaderRightProps = {
  menu?: boolean;
};

/**
 * 退出登录
 */
const loginOut = async (userId: string | undefined) => {
  await outLogin({ userId });
  removeToken();
  removeUserInfo();
  const { query = {} } = history.location;
  const { redirect } = query;
  // Note: There may be security issues, please note
  if (window.location.pathname !== '/user/login' && !redirect) {
    history.replace({ pathname: '/user/login' });
  }
};

const AvatarDropdown: React.FC<GlobalHeaderRightProps> = () => {
  const { initialState, setInitialState } = useModel('@@initialState');
  const onClick: MenuProps['onClick'] = useCallback(
    ({ key }: MenuInfo) => {
      if (key === 'logout') {
        // 退出登陆
        Socket.websocketclose();
        setInitialState((s) => ({ ...s, currentUser: undefined }));
        loginOut(initialState?.currentUser?.data?.id);
        return;
      }
      history.push(`/account/${key}`);
    },
    [setInitialState],
  );

  const loading = (
    <span className={`${styles.action} ${styles.account}`}>
      <Spin
        size="small"
        style={{
          marginLeft: 8,
          marginRight: 8,
        }}
      />
    </span>
  );

  if (!initialState) {
    return loading;
  }

  const { currentUser } = initialState;

  if (!currentUser || !currentUser.data) {
    return loading;
  }

  const items: MenuProps['items'] = [
    { label: '消息中心', key: 'messages', icon: <MessageOutlined /> }, // 菜单项务必填写 key
    { label: '个人设置', key: 'settings', icon: <SettingOutlined /> },
    {
      type: 'divider', // Must have
    },
    { label: '退出登录', key: 'logout', icon: <LogoutOutlined /> },
  ];

  return (
    <HeaderDropdown menu={{ items, onClick }}>
      <span className={`${styles.action} ${styles.account}`} onClick={(e) => e.preventDefault()}>
        <Avatar
          size="small"
          className={styles.avatar}
          src={
            currentUser?.data.avatar ||
            'https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png'
          }
          alt="avatar"
        />
        <span className={`${styles.name} anticon`}>{currentUser.data.realName}</span>
      </span>
    </HeaderDropdown>
  );
};

export default AvatarDropdown;
