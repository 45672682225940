import http from '@/utils/request';
// import { pageListItem } from './data.d';

// swagger 地址
const swaggerUrl = '/hohai/message';
/**
 * 待办-
 */
/** 获取-分页列表 */
export async function todoPage(params: API.pageParams) {
  return http.get({ url: `${swaggerUrl}/todo/page`, params: { ...params } }).then((data: any) => {
    if (data.code === '0' && data.data.list.length) {
      return {
        ...data,
        data: data?.data.list,
        total: data?.data.total,
      };
    }
  });
}

/** 删除 */
export async function removeTodo(params: any) {
  return http.post({ url: `${swaggerUrl}/todo/delete`, params });
}

//**已读信息 */
export async function readTodo(params: any) {
  return http.post({ url: `${swaggerUrl}/todo/read`, params });
}

/**
 * -公告通知-
 *  */
/** 获取分页列表 */
export async function bulletinPage(params: API.pageParams) {
  return http
    .get({ url: `${swaggerUrl}/bulletin/page`, params: { ...params } })
    .then((data: any) => {
      if (data.code === '0' && data.data.list.length) {
        return {
          ...data,
          data: data?.data.list,
          total: data?.data.total,
        };
      }
    });
}

/** 删除--公告通知 */
export async function removeBulletin(params: any) {
  return http.post({ url: `${swaggerUrl}/bulletin/delete`, params });
}

/** 已读信息 */
export async function readBulletin(params: any) {
  return http.post({ url: `${swaggerUrl}/bulletin/read`, params });
}

/**
 * -报警-
 */

/** 获取-分页列表 */
export async function alarmPage(params: API.pageParams) {
  return http.get({ url: `${swaggerUrl}/alarm/page`, params: { ...params } }).then((data: any) => {
    if (data.code === '0' && data.data.list.length) {
      return {
        ...data,
        data: data?.data.list,
        total: data?.data.total,
      };
    }
  });
}

/** 删除--报警 */
export async function removeAlarm(params: any) {
  return http.post({ url: `${swaggerUrl}/alarm/delete`, params });
}

/** 已读信息 */
export async function readAlarm(params: any) {
  return http.post({ url: `${swaggerUrl}/alarm/read`, params });
}
