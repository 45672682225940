import React, { useRef } from 'react';
import classnames from 'classnames';
import Modal from 'antd/es/modal';
import 'antd/es/modal/style/css';

const HModal = (props) => {
  const classId = useRef(new Date().getTime() + Math.random());

  const {
    wrapClassName = '',
    title = '', //对话框title
  } = props;

  //关闭对话框，初始化位置
  const onCancel = () => {
    if (props.onCancel && typeof props.onCancel === 'function') {
      props.onCancel();
      //TODO 关闭弹框会闪一下,待解决
    }
  };

  const bodyStyle = {
    ...props.bodyStyle,
  };

  const newProps = {
    maskClosable: false,
    destroyOnClose: true,
    width: 800,
    closable: true,
    title,
    wrapClassName: classnames(wrapClassName, classId.current),
    bodyStyle,
    onCancel,
    ...props,
    style: {
      ...props.style,
    },
  };
  console.log('newProps', newProps);

  return <Modal {...newProps}>{props.children}</Modal>;
};

HModal.info = Modal.info;
HModal.success = Modal.success;
HModal.error = Modal.error;
HModal.warning = Modal.warning;
HModal.confirm = Modal.confirm;

const size = {
  xsmall: 392,
  small: 500,
  middle: 744,
  large: 1096,
};

HModal.size = size;

export default HModal;
