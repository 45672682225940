import { useCallback, useState } from 'react';

// 交付工程管理
import {
  getProjectList,
  getProjectTypeList,
  getProjectCostTypeList,
  getProjectCostSubclassTypeList,
  getProjectCostUnitList,
} from '../services/Project';

import { changeTree, getTreeNode, addTreeRoot } from '@/utils/utils';

import type { DefaultOptionType } from 'antd/lib/select';
import type { DataNode } from 'antd/es/tree';

export default function useFarmlandM() {
  // 项目管理

  const [projectType, setProjectType] = useState<DefaultOptionType[]>([]); // 项目管理-项目类型列表
  const [projectTypeTree, setProjectTypeTree] = useState<DataNode[]>([]); // 项目管理-项目类型树
  const [projectList, setProjectList] = useState<DefaultOptionType[]>([]); // 项目管理-项目列表
  const [projectTree, setProjectTree] = useState<DataNode[]>([]); // 项目管理-项目树
  const [projectCostTypeList, setProjectCostTypeList] = useState<DefaultOptionType[]>([]); // 项目费用类型
  const [projectCostSubclassTypeList, setProjectCostSubclassTypeList] = useState<
    DefaultOptionType[]
  >([]); // 项目成本分项
  const [projectCostUnitList, setProjectCostUnitList] = useState<DefaultOptionType[]>([]); // 项目成本单位
  const [projectByContract, setProjectByContract] = useState<DataNode[]>([]); // 合同下项目列表
  const [projectMemberList, setProjectMemberList] = useState<API.noticeItem[]>([]); // 项目成员列表

  // 获取项目管理-项目类型列表-树
  const getProjectTypeListData = useCallback(async () => {
    const res = await getProjectTypeList();
    if (res.data) {
      const treeData: any[] = [
        {
          id: '-1',
          key: '-1',
          name: '全部项目类型',
          children: res.data,
          // selectable: false,
        },
      ];
      setProjectTypeTree(changeTree(treeData, 'name', 'id', 'children'));
      setProjectType(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.code,
        })),
      );
    }
  }, []);

  // 获取项目管理-项目列表-树
  const getProjectListData = useCallback(async (params: any) => {
    const res = await getProjectList(params);
    if (res.data) {
      const treeData: any[] = [
        {
          id: '-1',
          key: '-1',
          name: '全部项目',
          children: res.data,
        },
      ];
      setProjectTree(changeTree(treeData, 'name', 'id', 'children'));
      setProjectList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.code,
        })),
      );
      return res.data.map((item: any) => ({
        ...item,
        id: item.id,
        label: item.name,
        value: item.code,
      }));
    }
  }, []);

  // 获取项目管理-费用类型
  const getProjectCostTypeListData = useCallback(async (params: any) => {
    const res = await getProjectCostTypeList(params);
    if (res.data) {
      setProjectCostTypeList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.id,
        })),
      );
    }
  }, []);

  // 获取项目管理-成本分项类型
  const getProjectCostSubclassTypeListData = useCallback(async (params: any) => {
    const res = await getProjectCostSubclassTypeList(params);
    if (res.data) {
      setProjectCostSubclassTypeList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.id,
        })),
      );
    }
  }, []);

  // 获取项目管理-成本单位
  const getProjectCostUnitListData = useCallback(async (params: any) => {
    const res = await getProjectCostUnitList(params);
    if (res.data) {
      setProjectCostUnitList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.id,
        })),
      );
    }
  }, []);

  return {
    /**
     * 交付工程管理
     */
    // 获取项目管理-项目列表
    projectList,
    projectTree,
    getProjectListData,
    /** 获取项目类型 */
    projectType,
    projectTypeTree,
    getProjectTypeListData,
    /** 项目成本类型 */
    projectCostTypeList,
    getProjectCostTypeListData,
    /** 项目成本分项类型 */
    projectCostSubclassTypeList,
    getProjectCostSubclassTypeListData,
    /** 项目成本单位 */
    projectCostUnitList,
    getProjectCostUnitListData,
  };
}
