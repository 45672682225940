import http from '@/utils/request';

/**
 *  ---高标准农田---
 *  */
/** 获取灌区 -- irrigation/area/getList */
export async function getIrrigationList(params: any) {
  return http.get({ url: `/hohai/farmland/irrigation/area/getList`, params });
}

/** 获取作物列表 -- crop/getList */
export async function getCropList(params: any) {
  return http.get({ url: `/hohai/farmland/crop/getList`, params });
}

/** 获取种植单元列表 -- planting/unit/getList */
export async function getPlantingList(params: any) {
  return http.get({ url: `/hohai/farmland/planting/unit/getList`, params });
}

/** 根据泵房ID获取种植单元列表 -- /pipe/network/monitor/getPlantUnitByPumpRoomIdList */
export async function getPlantingByPumpRoomList(params: any) {
  return http.get({
    url: `/hohai/farmland/pipe/network/monitor/getPlantUnitByPumpRoomIdList`,
    params,
  });
}
/** 根据泵房ID获取系统列表 -- /pipe/network/monitor/getPlantUnitByPumpRoomIdList */
export async function getIrrigationSysByPumpRoomId(params: any) {
  return http.get({
    url: `/hohai/farmland/pipe/network/monitor/getIrrigationSysByPumpRoomId`,
    params,
  });
}

/** 获取灌溉系统列表 -- /irrigation/system/getList */
export async function getIrrigationSystemList(params: any) {
  return http.get({ url: `/hohai/farmland/irrigation/system/getList`, params });
}

/** 获取种植单元作物列表 -- planting/crop/getList */
export async function getPlantingCropList(params: any) {
  return http.get({ url: `/hohai/farmland/planting/crop/getList`, params });
}

/** 获取首部泵房列表 -- pump/room/getList */
export async function getPumpRoomList(params: any) {
  return http.get({ url: `/hohai/farmland/pump/room/getList`, params });
}

/** 获取首部泵房设备列表 -- pump/room/device/getList */
export async function getPumpRoomDeviceList(params: any) {
  return http.get({ url: `/hohai/farmland/pump/room/device/getList`, params });
}

/**
 * @description 获取泵房站点列表 -- site/getPumpRoomSiteList
 * */
export async function getPumpRoomSiteList(params: any) {
  return http.get({ url: `/hohai/farmland/site/getPumpRoomSiteList`, params });
}

/**
 * @description 获取闸站站点列表 -- site/getGateSiteList
 * */
export async function getGateSiteList(params: any) {
  return http.get({ url: `/hohai/farmland/site/getGateSiteList`, params });
}

/** 获取管网列表 -- pipe/network/getList */
export async function getPipeList(params: any) {
  return http.get({ url: `/hohai/farmland/element/getList`, params });
}
/** 获取站点列表 -- site/getRealDataList */
export async function getSiteList(params: any) {
  return http.get({ url: `/hohai/farmland/site/getRealDataList`, params });
}

/**
 * @description 获取站点类型列表 -- /hohai/farmland/siteType/getList
 *  */
export async function getSiteTypeList(params: any) {
  return http.get({ url: `/hohai/farmland/siteType/getList`, params });
}

/**
 * @description 获取灌区用户列表 -- /farmland/farmers/getList
 * @param code
 * @param name
 * @param irrAreaId
 * */
export async function getFarmerList(params: any) {
  return http.post({ url: `/hohai/farmland/farmers/getList`, params });
}

/**
 * @description 获取渠系列表 -- /farmland/canal/getList
 * @param code
 * @param name
 * @param irrAreaId
 * @param canalType MAIN_CANAL, BRANCH_CANAL, BUCKET_CANAL
 * @param canalForm
 * */
export async function getCanalList(params: any) {
  return http.get({ url: `/hohai/farmland/canal/getList`, params });
}
/**
 * @description 获取指令集列表 -- /farmland/instruction/set/getList
 * @param id
 * @param name
 * @param irrAreaId
 * @param irrAreaIds
 * @param tenantId
 * */
export async function getInstructionList(params: any) {
  return http.get({ url: `/hohai/farmland/instruction/set/getList`, params });
}
/**
 * @description 获取采购商列表 -- /farmland/purchaser/getList
 * @param name
 * @param enable
 * @param ids array[string]
 * */
export async function getPurchaserList(params: any) {
  return http.get({ url: `/hohai/farmland/purchaser/getList`, params });
}

/**
 * @description 获取统计数据  site/getSiteHistoryList
 * @param siteId
 * @param startTime
 * @param endTime
 *
 * */
export async function getSiteHistoryList(params: any) {
  return http.get({
    url: `/hohai/farmland/site/getSiteHistoryList`,
    params,
  });
}

/**
 * @description 获取站点详情  visualization/getSiteDetail
 *
 * @param siteId  站点id
 * @param coordinatesType
 * */
export async function getSiteDetail(params: any) {
  return http.post({
    url: `/hohai/farmland/visualization/getSiteDetail`,
    params,
  });
}
/**
 * @description 获取设施管理  farmland/tailwater/facilityManagement/getList
 *
 * @param coordinatesType
 * */
export async function getFacilityList(data: any) {
  return http.post({
    url: `/hohai/farmland/tailwater/facilityManagement/getList`,
    data,
  });
}

/**
 * @description 获取节点列表  wisdom/topologyDiagramNode/getList
 *
 * @param type string 类型
 * */
export async function getNodeList(params: any) {
  return http.get({
    url: `/hohai/farmland/wisdom/topologyDiagramNode/getList`,
    params,
  });
}

/**
 * @description 获取已绑定设备下拉  inspection/tailwater/point/getBindDeviceList
 * */
export async function getBindDeviceList() {
  return http.get({ url: '/hohai/inspection/tailwater/point/getBindDeviceList' });
}

/**
 *  @description 获取巡查点下拉  /point/list */
export async function getPatrolPointList() {
  return http.get({ url: '/hohai/inspection/tailwater/point/list' });
}

/**
 *  @description 获取任务类型  /subclass/list */
export async function getTaskTypeList() {
  return http.get({ url: '/hohai/inspection/tailwater/subclass/list' });
}
