import { useCallback, useState } from 'react';
import type { DefaultOptionType } from 'antd/lib/select';

import {
  getDataDictionaryList,
  getDataItemList,
  getFacilityTypeList,
  getFacilityList,
} from '../services/Infrastructure';

export default function useInfrastructureM() {
  // 字段类型枚举
  const fieldTypeEnum = [
    { id: '1', value: 'TEXT_BOX', label: '单行文本框' },
    { id: '2', value: 'NUMBER_BOX', label: '数字框' },
    { id: '3', value: 'INTEGER_BOX', label: '整数框' },
    { id: '4', value: 'DROP_DOWN_RADIO_BOX', label: '下拉单选' },
    { id: '5', value: 'DROP_DOWN_MULTI_BOX', label: '下拉多选' },
    { id: '6', value: 'DATE_CONTROL', label: '日期控件' },
    { id: '7', value: 'TIME_CONTROL', label: '时间控件' },
    { id: '8', value: 'MULTI_LINE_TEXT_BOX', label: '多行文本框' },
    { id: '9', value: 'IMAGE_UPLOAD_CONTROL', label: '图片上传控件' },
    { id: '10', value: 'FILE_UPLOAD_CONTROL', label: '文件上传控件' },
    { id: '11', value: 'EQUIPMENT_RADIO_BOX', label: '设备单选框' },
    { id: '12', value: 'DEVICE_MULTI_BOX', label: '设备多选框' },
    { id: '13', value: 'DEVICE_DATA', label: '设备数据' },
    { id: '14', value: 'INFRASTRUCTURE_RADIO_BOX', label: '基础设施单选框' },
    { id: '16', value: 'INFRASTRUCTURE_MULTI_BOX', label: '基础设施多选框' },
  ];
  // 基础设施
  const [dataDictionaryList, setDataDictionaryList] = useState<DefaultOptionType[]>([]); // 数据字典
  const [dataItemList, setDataItemList] = useState<DefaultOptionType[]>([]); // 数据字典数据项
  const [facilityTypeList, setFacilityTypeList] = useState<DefaultOptionType[]>([]); // 设施类型
  const [facilityList, setFacilityList] = useState<DefaultOptionType[]>([]); // 基础设施

  /** 获取--数据字典--列表 */
  const getDataDictionaryListData = useCallback(async (params: any) => {
    const res = await getDataDictionaryList(params);
    if (res.data) {
      setDataDictionaryList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.id,
          code: item.code,
        })),
      );
    }
  }, []);
  /** 获取--数据字典数据项--列表 */
  const getDataItemListData = useCallback(async (params: any) => {
    const res = await getDataItemList(params);
    if (res.data) {
      setDataItemList(
        res.data.map((item: any) => ({
          ...item,
          id: item.key,
          label: item.val,
          value: item.key,
        })),
      );
    }
  }, []);
  /** 获取--设施类型--列表 */
  const getFacilityTypeListData = useCallback(async (params: any) => {
    const res = await getFacilityTypeList(params);
    if (res.data) {
      setFacilityTypeList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.id,
          code: item.code,
        })),
      );
    }
  }, []);
  /** 获取--基础设施--列表 */
  const getFacilityListData = useCallback(async (params: any) => {
    const res = await getFacilityList(params);
    if (params?.typeId === 'none') {
      setFacilityList([]);
    } else {
      if (res.data) {
        setFacilityList(
          res.data.map((item: any) => ({
            ...item,
            id: item.id,
            label: item.name,
            value: item.id,
            code: item.code,
          })),
        );
      }
    }
  }, []);

  return {
    fieldTypeEnum,
    /** 获取--数据字典 */
    dataDictionaryList,
    getDataDictionaryListData,
    /** 获取--数据字典数据项 */
    dataItemList,
    getDataItemListData,
    /** 获取--设施类型--列表 */
    facilityTypeList,
    getFacilityTypeListData,
    /** 获取--设施列表 */
    facilityList,
    getFacilityListData,
  };
}
