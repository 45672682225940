import http from '@/utils/request';

/** 登录接口 POST */
export async function login(data: API.LoginParams) {
  return http.post({
    url: '/hohai/auth/login/loginByUserName',
    data,
    options: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  });
}

/** 获取当前的用户信息 */
export async function currentUser() {
  return http.get({ url: '/hohai/auth/login/info' });
}
/** 退出登录 */
export async function outLogin(params: { userId: string | undefined }) {
  return http.get({ url: '/hohai/auth/login/logout', params });
}
/** 获取菜单树 */
export async function getMenuData(params: { userId: string }) {
  return http.get({ url: '/hohai/auth/menu/tree', params });
}

/** 获取租户列表 */
export async function getTenantList() {
  return http.get({ url: '/hohai/auth/tenant/anonymous/getList' });
}
