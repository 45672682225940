import React, { createRef } from 'react';

import RightContent from '@/components/RightContent';
import { PageLoading } from '@ant-design/pro-components';
import { history, RequestConfig } from 'umi';
// import defaultSettings from '../config/defaultSettings';
import { currentUser as queryCurrentUser } from './pages/user/Login/service';

import Provider from './store/provider';
import leftTopLogo from '../public/logo.svg';
import UnAccessible from './pages/403';
import '/assets/iconfont/iconfont.css';
import { getMenuTree } from '@/services/Common';
import { changeMenuTree, getToken, getUserInfo } from '@/utils/utils';

import type { RunTimeLayoutConfig } from 'umi';
import type { Settings as LayoutSettings } from '@ant-design/pro-components';
import type { MenuDataItem } from '@ant-design/pro-layout';

// 不登录可访问的路由列表
const unLoginAccess = [
  '/user/login',
  '/waterGate',
  '/farmer/cropManage/h5',
  '/fullView/fullView',
  '/fullView/fullViewItem',
];

const { NODE_ENV } = process.env;

const loginPath = '/user/login';

// const getRoutes = () => menuData;

/** 获取用户信息比较慢的时候会展示一个 loading */
export const initialStateConfig = {
  loading: <PageLoading />,
};

// // router 图片列表
// const IconMap = {
//   Setting: <SettingOutlined />,
//   Switcher: <SwitcherOutlined />,
// };

// 加菜菜单 增加图片
const loopMenuItem = (menus: MenuDataItem[]): MenuDataItem[] =>
  menus.map(({ icon, routes, ...item }) => ({
    ...item,
    // icon: icon && IconMap[icon as string],
    routes: routes && loopMenuItem(routes),
  }));

export const layoutActionRef = createRef<{ reload: () => void }>();

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  currentUser?: API.CurrentUser;
  loading?: boolean;
  fetchUserInfo?: () => Promise<API.CurrentUser | undefined>;
}> {
  const fetchUserInfo = async () => {
    try {
      const msg = await queryCurrentUser();
      if (msg.code === '0') {
        return msg;
      } else {
        history.push(loginPath);
      }
    } catch (error) {
      history.push(loginPath);
    }
    return undefined;
  };
  // 如果不是登录页面，执行
  if (
    unLoginAccess.indexOf(history.location.pathname) === -1
    // history.location.pathname !== loginPath &&
    // location.hash !== '#/waterGate' &&
    // location.hash !== '#/fullView' &&
    // history.location.pathname !== '/farmer/cropManage/h5'
  ) {
    const currentUser = await fetchUserInfo();
    return {
      fetchUserInfo,
      currentUser,
      settings: {},
      // settings: defaultSettings,
    };
  }
  return {
    fetchUserInfo,
    settings: {},
    // settings: defaultSettings,
  };
}

// ProLayout 支持的api https://procomponents.ant.design/components/layout
export const layout: RunTimeLayoutConfig = ({ initialState }) => {
  return {
    title: '智慧水业务平台',
    logo: leftTopLogo, //'/logo.svg',
    actionRef: layoutActionRef,
    /*
     要使用 iconFont 的图标必须满足两个条件
     传入一个 iconFont 的 url 链接
     icon 命名必须以 icon-开头 
     */
    iconfontUrl: '//at.alicdn.com/t/c/font_3451006_m4cf0wnvy2.js',
    rightContentRender: () => <RightContent />,
    disableContentMargin: false,
    waterMarkProps: {
      // 水印 不需要传空对象即可
      // content: `${initialState?.currentUser?.data?.realName}（${initialState?.currentUser?.data?.userName}）`,
    },
    // footerRender: () => <Footer />,
    onPageChange: () => {
      const { location } = history;
      // layoutActionRef.current?.reload(); // 手动控制菜单刷新
      // 如果没有登录，重定向到 login
      if (
        !initialState?.currentUser &&
        unLoginAccess.indexOf(history.location.pathname) === -1
        // location.pathname !== loginPath &&
        // location.pathname !== '/waterGate' &&
        // location.pathname !== '/fullView' &&
        // location.pathname !== '/farmer/cropManage/h5'
      ) {
        history.push(loginPath);
      }
    },
    menu: {
      locale: false, // 关闭国际化
      params: {
        userId: initialState?.currentUser?.data?.id,
      },
      request: async (params: any) => {
        if (params.userId && getToken()) {
          const res = await getMenuTree({
            userId: initialState?.currentUser?.data?.id,
            rootRouting: `/${history.location.pathname.split('/')[1]}`,
          });
          let tempMenuData: API.routeItem[] = [];
          if (res.data.length) {
            tempMenuData = changeMenuTree(res.data[0]?.routes || res.data);
          }
          return tempMenuData;
        } else return;
      },
    },

    menuHeaderRender: undefined,
    // 自定义 403 页面
    unAccessible: <UnAccessible />,
    // 增加一个 loading 的状态
    ...initialState?.settings,
  };
};

// 根结点增加Provider
export function rootContainer(container: any) {
  //定义_Container
  return React.createElement(Provider, null, container);
}
