import { Space, Dropdown } from 'antd';
import { SendOutlined, MenuUnfoldOutlined, MenuOutlined } from '@ant-design/icons';
import React from 'react';
import { useModel, history, useAccess } from 'umi';
import Avatar from './AvatarDropdown';
// import HeaderSearch from '../HeaderSearch';
import NoticeIconView from '../NoticeIcon';
import { layoutActionRef } from '@/app';
import styles from './index.less';
import { getUserInfo, setUserInfo } from '@/utils/utils';
export type SiderTheme = 'light' | 'dark';

const GlobalHeaderRight: React.FC = () => {
  const { initialState } = useModel('@@initialState');

  // 权限
  const access = useAccess();

  if (!initialState || !initialState.settings) {
    return null;
  }

  const { navTheme, layout } = initialState.settings;
  let className = styles.right;

  if ((navTheme === 'dark' && layout === 'top') || layout === 'mix') {
    className = `${styles.right}  ${styles.dark}`;
  }

  return (
    <Space className={className}>
      {/* {(access.admin || !access.farmland) && (
        <span
          className={styles.action}
          onClick={() => {
            // window.open('https://pro.ant.design/docs/getting-started');
            history.push('/oneKeySend');
          }}
          title="一键派发"
        >
          <SendOutlined />
        </span>
      )} */}

      <Dropdown
        menu={{
          items: getUserInfo()
            .menuTree.filter((item: any) => !item.hideInMenu)
            .map((item: any) => ({
              key: item.id,
              label: item.menuName,
              onClick: () => {
                // setUserInfo(JSON.stringify({ ...getUserInfo(), menuId: item.id }));
                history.push(item.path);
                setTimeout(() => {
                  layoutActionRef.current?.reload(); // 手动刷新菜单
                }, 500);
              },
            })),
        }}
        trigger={['click']}
      >
        <span
          className={styles.action}
          // onClick={() => {
          //   // window.open('https://pro.ant.design/docs/getting-started');
          //   history.push('/user/menu');
          // }}
          title="菜单"
        >
          <MenuUnfoldOutlined />
        </span>
      </Dropdown>

      <span
        className={styles.action}
        onClick={() => {
          // window.open('https://pro.ant.design/docs/getting-started');
          history.push('/user/menu');
        }}
        title="菜单导航"
      >
        <MenuOutlined />
      </span>

      {/* {(access.admin || !access.farmland) && (
        <span
          className={styles.action}
          onClick={() => {
            history.push('/waterGate');
          }}
          title="水闸大屏"
        >
          <FundOutlined />
        </span>
      )} */}
      <NoticeIconView />
      <Avatar />
    </Space>
  );
};

export default GlobalHeaderRight;
